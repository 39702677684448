import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider, } from "react-router-dom";
import WeekPicker from "./components/WeekPicker";
import EmployeeList from "./pages/EmployeeList";
import AddEmployee from "./pages/AddEmployee";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import UserList from "./pages/UserList";
import Backup from "./pages/Backup";
import SubcontractorList from "./pages/SubcontractorList";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Dashboard/>,
    },
    {
        path: "jobs",
        element: <WeekPicker/>,
    },
    {
        path: "employees",
        element: <EmployeeList/>,
    },
    {
        path: "users",
        element: <UserList/>,
    },
    {
        path: "createEmployee",
        element: <AddEmployee/>,
    },
    {
      path: "backup",
      element: <Backup/>,
    },
    {
        path: "subcontractor",
        element: <SubcontractorList/>,
    },
    {
        path: "login",
        element: <Login/>,
    }
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
