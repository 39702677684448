import axios from 'axios';
import {BASEURL} from "../Constants";

class FileService {
    delete(id, file) {
        axios.delete(`${BASEURL}/file/${id}/${file}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        })
            .then((response) => {
                console.log('File deleted successfully');
            })
            .catch((error) => {
                console.error('Error deleting file:', error);
                // Handle error condition
            });
    };

    getFiles(employeeId) {
        return axios.get(`${BASEURL}/file/employee/${employeeId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error('Error fetching files:', error);
                // Handle error condition
            });
    }

    uploadFile(employeeId, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.post(`${BASEURL}/file/${employeeId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response) => {
                console.log('File uploaded successfully');
                return response.data;
            })
            .catch((error) => {
                console.error('Error uploading file:', error);
                // Handle error condition
            });

    }

    rename(userId, file, newName) {
        axios.put(`${BASEURL}/file/${userId}/${file}/${newName}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                }
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error('Error renaming file:', error);
                return error;
            });

    }
}

export default new FileService();
