import React, {useState} from 'react';
import './EditForm.css';
import EmployeeService from "../services/EmployeeService";
import SubcontractorService from "../services/SubcontractorService";
import { useNavigate } from 'react-router-dom';

const EmployeeEditForm = ({employee, onEdit, onClose}) => {
    const [id, setId] = useState(employee.id);
    const [firstName, setFirstName] = useState(employee.firstName);
    const [lastName, setLastName] = useState(employee.lastName);
    const [nationality, setNationality] = useState(employee.nationality);
    const [gender, setGender] = useState(employee.gender);
    const [citizenServiceNumber, setCitizenServiceNumber] = useState(employee.citizenServiceNumber);
    const [subcontractor, setSubcontractor] = useState(employee.subcontractor);
    const [email, setEmail] = useState(employee.email);
    const [telephoneNumber, setTelephoneNumber] = useState(employee.telephoneNumber);
    const [dateOfEmployment, setDateOfEmployment] = useState(employee.dateOfEmployment);
    const [hourlyWage, setHourlyWage] = useState(employee.hourlyWage);
    const [bankAccountNumber, setBankAccountNumber] = useState(employee.bankAccountNumber);
    const [kvkNumber, setKvkNumber] = useState(employee.kvkNumber);
    const [street, setStreet] = useState(employee.street);
    const [houseNumber, setHouseNumber] = useState(employee.houseNumber);
    const [city, setCity] = useState(employee.city);
    const [postalCode, setPostalCode] = useState(employee.zipcode);
    const navigate = useNavigate();
    const [subcontractors, setSubcontractors] = useState([]);
    const [company, setCompany] = useState(employee.company);

    const fetchSubcontractors = async () => {
        const subcontractorData = await SubcontractorService.getSubcontractors();
        setSubcontractors(subcontractorData);
    };

    React.useEffect(() => {
        try {
            fetchSubcontractors();
        } catch (e) {
            console.log(e);
            navigate("/login");
        }

    }, []);

    const handleSave = () => {
        EmployeeService.updateEmployee(
            id, firstName, lastName, employee.insertion, employee.birthDate, employee.birthplace, employee.countryOfBirth,
            employee.nationality, employee.gender, employee.citizenServiceNumber, email, telephoneNumber, dateOfEmployment, hourlyWage,
            bankAccountNumber, kvkNumber, subcontractor, company, employee.submitterName, employee.password, street, houseNumber, city, postalCode
        ).then(r => onEdit(r));


        // Close form
        onClose();
    };

    return (
        <div className="edit-form-overlay">
            <div className="edit-form">
                <div className="edit-form-row">
                    <label htmlFor="first-name">First name:</label>
                    <input
                        id="first-name"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="last-name">Last name:</label>
                    <input
                        id="last-name"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="telephone-number">Telephone number:</label>
                    <input
                        id="telephone-number"
                        type="number"
                        value={telephoneNumber}
                        onChange={(e) => setTelephoneNumber(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="hourly-wage">Hourly wage:</label>
                    <input
                        id="hourly-wage"
                        type="number"
                        value={hourlyWage}
                        onChange={(e) => setHourlyWage(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="bank-account-number">Bank account number:</label>
                    <input
                        id="bank-account-number"
                        type="text"
                        value={bankAccountNumber}
                        onChange={(e) => setBankAccountNumber(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="kvk-number">KVK number:</label>
                    <input
                        id="kvk-number"
                        type="text"
                        value={kvkNumber}
                        onChange={(e) => setKvkNumber(e.target.value)}
                        className="edit-form-input"
                    />
                </div>

                <div className="edit-form-row">
                    <label htmlFor="nationality">Nationality:</label>
                    <input
                        id="nationality"
                        type="text"
                        value={nationality}
                        onChange={(e) => setNationality(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="city">City:</label>
                    <input
                        id="city"
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="postal-code">Postal code:</label>
                    <input
                        id="postal-code"
                        type="text"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="street">Street:</label>
                    <input
                        id="street"
                        type="text"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="house-number">House number:</label>
                    <input
                        id="house-number"
                        type="text"
                        value={houseNumber}
                        onChange={(e) => setHouseNumber(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="subcontractor">Subcontractor:</label>
                    <select id="subcontractor" name="subcontractor" value={subcontractor} onChange={(e) => setSubcontractor(e.target.value)}>
                        <option value="">--Select--</option>
                        {subcontractors.map((subcontractor) => (
                            <option value={subcontractor.name}>{subcontractor.name}</option>
                        ))}
                    </select>
                </div>
                <div className="edit-form-row">
                    <label htmlFor="company">Company:</label>
                    <select id="company" name="company" value={company} onChange={(e) => setCompany(e.target.value)}>
                        <option value="">--Select--</option>
                        <option value="Crown">Crown</option>
                        <option value="North-Star">North-Star</option>
                        <option value="Oost-West">Oost-West</option>
                    </select>
                </div>
                <div className="edit-form-actions">
                    <button className="edit-form-save" onClick={handleSave}>
                        Save
                    </button>
                    <button className="edit-form-close" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EmployeeEditForm;
