import axios from 'axios';
import {BASEURL} from "../Constants";

class ProjectService {

    /**
     * Get all projects
     * @returns {Promise} All the projects
     */
    getProjects() {
        return axios.get(`${BASEURL}/project`)
            .then(response => {
                return response.data;
            });
    }
}

export default new ProjectService();
