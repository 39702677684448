import React, {useEffect, useState} from 'react';
import './DayEditForm.css';
import ProjectService from '../services/ProjectService';
import JobService from "../services/JobService";

const DayEditForm = ({ day, onSave, onClose }) => {
    const [id, setId] = useState(day.id);
    const [date, setDate] = useState(day.date);
    const [startTime, setStartTime] = useState(day.startTime);
    const [endTime, setEndTime] = useState(day.endTime);
    const [breakTime, setBreakTime] = useState(day.breakTime);
    const [project, setProject] = useState(day.projectName);
    const [location, setLocation] = useState(day.location);
    const [foreman, setForeman] = useState(day.foreman);
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        ProjectService.getProjects().then(data => {
            setProjects(data);
        });
    }, []);
    const handleSave = () => {
        // Check if all fields are filled in
        if (
            !startTime ||
            !endTime ||
            !breakTime ||
            !project ||
            !location ||
            !foreman
        ) {
            console.log(startTime, endTime, breakTime, project, location, foreman);
            alert('Please fill in all fields');
            return;
        }
        // Check if start time is before end time
        if (startTime >= endTime) {
            alert('Start time must be before end time');
            return;
        }
        // Check if hour already exists

        // try {
        //     JobService.getJob(id).then(data => {
        //         if (data) {
        //             JobService.updateJob(id, date, startTime, endTime, breakTime, project, location, foreman).then(r => {
        //                 console.log(r);
        //
        //             });
        //             return console.log('hour updated');
        //         }
        //     });
        // } catch (e) {
        // }


        JobService.postJob(1, date, startTime, endTime, breakTime, project, location, foreman).then(r => {
        })
        // Close form
        onClose();
        };

    return (
        <div className="day-edit-form-overlay">
            <div className="day-edit-form">
                <h2 className="day-edit-form-title">{day.day}</h2>
                <div className="day-edit-form-row">
                    <label htmlFor="start-time">Start Time:</label>
                    <input
                        id="start-time"
                        type="time"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        className="day-edit-form-input"
                    />
                </div>
                <div className="day-edit-form-row">
                    <label htmlFor="end-time">End Time:</label>
                    <input
                        id="end-time"
                        type="time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        className="day-edit-form-input"
                    />
                </div>
                <div className="day-edit-form-row">
                    <label htmlFor="break-time">Break Time:</label>
                    <input
                        id="break-time"
                        type="time"
                        value={breakTime}
                        onChange={(e) => setBreakTime(e.target.value)}
                        className="day-edit-form-input"
                    />
                </div>
                <div className="day-edit-form-row">
                    <label htmlFor="project-name">Project:</label>
                {/*    The Projects are a promise array so its can't be mapped loop through all the Arrays in the promise*/}
                    <select
                        id="project-name"
                        value={project}
                        onChange={(e) => setProject(e.target.value)}
                        className="day-edit-form-input"
                    >
                        {projects.map((project) => (
                            <option key={project.id} value={project.id}>
                                {project.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="day-edit-form-row">
                    <label htmlFor="location">Location:</label>
                    <input
                        id="location"
                        type="text"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        className="day-edit-form-input"
                    />
                </div>
                <div className="day-edit-form-row">
                    <label htmlFor="foreman">Foreman:</label>
                    <input
                        id="foreman"
                        type="text"
                        value={foreman}
                        onChange={(e) => setForeman(e.target.value)}
                        className="day-edit-form-input"
                    />
                </div>
                <div className="day-edit-form-actions">
                    <button className="day-edit-form-button day-edit-form-button-save" onClick={handleSave}>
                        Save
                    </button>
                    <button className="day-edit-form-button day-edit-form-button-cancel" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DayEditForm;
