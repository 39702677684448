class Utils {

    /**
     * Capitalizes the first letter of each word in a string
     * @param {string} str the string to capitalize
     * @returns {string} the capitalized string
     */
    static capitalizeWords(str) {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    /**
     * Formats a date to a string in the format dd-mm-yyyy
     * @param date the date to format
     * @returns {string} the formatted date
     */
    static formatDate(date) {
        const d = new Date(date);
        const day = d.getDate();
        const month = d.getMonth() + 1;
        const year = d.getFullYear();
        return `${day}-${month}-${year}`;
    }

    /**
     * Converts a date string in the format yy-mm-dd to a date object
     * @param birthDateString
     * @returns {string} the date
     */
    static convertDate(birthDateString) {
        const year = birthDateString.substring(0, 2);
        const month = birthDateString.substring(2, 4);
        const day = birthDateString.substring(4, 6);
        // calculate the full year based on the 2-digit year
        const fullYear = year < 50 ? 2000 + parseInt(year) : 1900 + parseInt(year);

        // yyyy-mm-dd
        return `${fullYear}-${month}-${day}`;
    }

    /**
     * Get the name of a document type based on the document type code
     * @param documentType the document type code
     * @returns {string} the name of the document type
     */
    static documentTypeToName(documentType) {
        switch (documentType) {
            case 1:
                return 'Paspoort';
            case 2:
                return 'ID';
            case 3:
                return 'Verblijfsvergunning'
        }

    }

    /**
     * Get the gender character from the gender code
     * @param genderCode the gender number/code
     * @returns {string} the gender character
     */
    static getGenderFromCode(genderCode) {
        switch (genderCode) {
            case 1:
                return 'M';
            case 2:
                return 'V';
            case 3:
                return 'O';
        }
    }

    /**
     * Checks if a file is an image file
     * @param file
     * @returns {*}
     */
    static isImageFile = (file) => {
        return file.name.endsWith('.png') || file.name.endsWith('.jpg') ||
            file.name.endsWith('.jpeg') || file.name.endsWith('.gif');
    }
}

export default Utils;
