import React, {useState} from 'react';
import './EditForm.css';
import UserService from "../services/UserService";

const UserCreateForm = ({onSave, onClose}) => {
    const [name, setName] = useState("");
    const [admin, setAdmin] = useState(false);
    const [password, setPassword] = useState("");

    const handleSave = async () => {
        // Check if all fields are filled
        if (name === "" || password === "") {
            alert("Please fill all fields!");
            return;
        }
        await UserService.createUser(
            name, password, admin);

        onClose();
    };

    return (
        <div className="edit-form-overlay">
            <div className="edit-form">
                <div className="edit-form-row">
                    <label htmlFor="name">Name:</label>
                    <input
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="password">Password:</label>
                    <input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="admin">Is Admin:</label>
                    <select
                        id="admin"
                        value={admin}
                        onChange={(e) => setAdmin(e.target.value)}
                        className="edit-form-input"
                    >
                        <option value='true'>
                            Yes
                        </option>
                        <option value='false'>
                            No
                        </option>
                    </select>
                </div>
                <div className="edit-form-actions">
                    <button className="edit-form-save" onClick={handleSave}>
                        Create
                    </button>
                    <button className="edit-form-close" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserCreateForm;
