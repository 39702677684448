import * as React from 'react';
import NavBar from "../components/NavBar";
import {ProSidebarProvider} from "react-pro-sidebar";
import {useEffect} from "react";
import BackupService from "../services/BackupService";
import "./Backup.css"

const Backup = () => {

    const [backups, setBackups] = React.useState([]);
    const [selectedBackup, setSelectedBackup] = React.useState('');

    const handleBackup = () => {
        // Handle backup
        console.log("Backup");
        BackupService.backup().then(response => {
            if (response) {
                console.log("Backup successful");
                getBackups();
                alert("Backup successful");
            }
        })
    };


    const getBackups = () => {
        BackupService.getBackups().then(response => {
            if (response) {
                setBackups(response);
            }
        });
    }
    useEffect(() => {
        getBackups();
    }, []);


const handleSelectChange = (event) => {
    setSelectedBackup(event.target.value);
}


return (
    <div>
        <ProSidebarProvider>
            <NavBar/>
        </ProSidebarProvider>
        <div className="backup-container">
            <h3>Backups</h3>
            <div>
                <select onChange={handleSelectChange} value={selectedBackup}>
                    <option value="" disabled>Backup List</option>
                    {backups.map((backup, index) => (
                        <option key={index} value={backup}>
                            {backup}
                        </option>
                    ))}
                </select>
            </div>
            <button className="backup-btn" onClick={handleBackup}>
                Backup Manually
            </button>
        </div>
    </div>
);
}
;

export default Backup;
