// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 100%;
    padding: 24px;
    flex-direction: column;
    display: flex;
    align-items: center;
}

.document-image {
    max-width: 100%;
    max-height: 400px;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 24px;
}

.document-image:hover {
    cursor: pointer;
    opacity: 0.8;
}

.edit-form-input:focus {
    outline: none;
    border-color: #2196f3;
    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);
}

.edit-form-actions {
    display: flex;
    justify-content: space-between;
}

.edit-form-save, .edit-form-close {
    background: #2196f3;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
}

.edit-form-close {
    background: lightgray;
}

.replace-document-button {
    background: #2196f3;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
}

.replace-document-button:hover {
    background: #1976d2;
}`, "",{"version":3,"sources":["webpack://./src/components/DocumentEditForm.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,YAAY;IACZ,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,wCAAwC;IACxC,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,gDAAgD;AACpD;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".left {\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    width: 400px;\n    max-width: 100%;\n    padding: 24px;\n    flex-direction: column;\n    display: flex;\n    align-items: center;\n}\n\n.document-image {\n    max-width: 100%;\n    max-height: 400px;\n    object-fit: contain;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    margin-bottom: 24px;\n}\n\n.document-image:hover {\n    cursor: pointer;\n    opacity: 0.8;\n}\n\n.edit-form-input:focus {\n    outline: none;\n    border-color: #2196f3;\n    box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, 0.5);\n}\n\n.edit-form-actions {\n    display: flex;\n    justify-content: space-between;\n}\n\n.edit-form-save, .edit-form-close {\n    background: #2196f3;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    padding: 8px 16px;\n    cursor: pointer;\n}\n\n.edit-form-close {\n    background: lightgray;\n}\n\n.replace-document-button {\n    background: #2196f3;\n    color: white;\n    border: none;\n    border-radius: 4px;\n    padding: 8px 16px;\n    cursor: pointer;\n}\n\n.replace-document-button:hover {\n    background: #1976d2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
