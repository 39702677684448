import React, {useState} from 'react';
import "./EmployeeView.css";

const EmployeeView = ({employee, onSave, onClose}) => {
    console.log(employee);
    const [id, setId] = useState(employee.id);
    const [firstName, setFirstName] = useState(employee.firstName);
    const [lastName, setLastName] = useState(employee.lastName);
    const [birthDate, setBirthDate] = useState(employee.birthDate);
    const [countryOfBirth, setCountryOfBirth] = useState(employee.countryOfBirth);
    const [nationality, setNationality] = useState(employee.nationality);
    // const [gender, setGender] = useState(employee.gender);
    // if gender = 0 show female if its 1 show male otherwise show other
    const [gender, setGender] = useState(employee.gender === 0 ? "Female" : "Male")
    const [citizenServiceNumber, setCitizenServiceNumber] = useState(employee.citizenServiceNumber);
    const [email, setEmail] = useState(employee.email);
    const [telephoneNumber, setTelephoneNumber] = useState(employee.telephoneNumber);
    const [dateOfEmployment, setDateOfEmployment] = useState(employee.dateOfEmployment);
    const [hourlyWage, setHourlyWage] = useState(employee.hourlyWage);
    const [bankAccountNumber, setBankAccountNumber] = useState(employee.bankAccountNumber);
    const [kvkNumber, setKvkNumber] = useState(employee.kvkNumber);
    const [submitterName, setSubmitterName] = useState(employee.submitterName);
    const [expiryDate, setExpiryDate] = useState(employee.expiryDate);
    const [documentNumber, setDocumentNumber] = useState(employee.documentNumber);
    const [dateOfIssue, setDateOfIssue] = useState(employee.dateOfIssue);
    const [documentType, setDocumentType] = useState(employee.documentType);
    const [street, setStreet] = useState(employee.street);
    const [houseNumber, setHouseNumber] = useState(employee.houseNumber);
    const [city, setCity] = useState(employee.city);
    const [zipcode, setZipcode] = useState(employee.zipcode);

    return (
        <div className="employee-info-container">
            <div className="employee-info-form">
                <h2 className="employee-info-title">Employee Info</h2>
                <div className="employee-info-row">
                    <p>
                        <strong>Name:</strong> {firstName} {lastName}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Birth Date:</strong> {birthDate}
                    </p>
                </div>
                <div className="employee-info-row">

                    <p>
                        <strong>Country of Birth:</strong> {countryOfBirth}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Nationality:</strong> {nationality}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Gender:</strong> {gender}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Citizen Service Number:</strong> {citizenServiceNumber}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Email:</strong> {email}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Telephone Number:</strong> {telephoneNumber}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Date of Employment:</strong> {dateOfEmployment}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Hourly Wage:</strong> {hourlyWage}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Bank Account Number:</strong> {bankAccountNumber}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>KvK Number:</strong> {kvkNumber}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Submitter Name:</strong> {submitterName}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Document Type:</strong> {documentType}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Date of Issue:</strong> {dateOfIssue}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Expiry Date:</strong> {expiryDate}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Document Number:</strong> {documentNumber}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>City:</strong> {city}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Street:</strong> {street}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>Zip Code:</strong> {zipcode}
                    </p>
                </div>
                <div className="employee-info-row">
                    <p>
                        <strong>House Number:</strong> {houseNumber}
                    </p>
                </div>
                <button className="employee-form-close" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
};
export default EmployeeView;