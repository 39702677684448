import axios from 'axios';
import {BASEURL} from "../Constants";

class BackupService {

    getBackups() {
        return axios.get(`${BASEURL}/backup`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.data;
            });
    }

    backup() {
        return axios.post(`${BASEURL}/backup`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.data;
            });
    }
}
export default new BackupService();
