// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.heading {
    margin-right: 100px;
    margin-top: 50px;
    font-size: 24px;
    font-weight: bold;
}

.document-list {
    margin-top: 20px;
}

.document-card {
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.document-card h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 25px;
}

.document-card .document-details {
    display: flex;
    flex-direction: column;
}

.document-card .expiry-date {
    display: flex;
    font-weight: bold;
    color: #ff0000;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".dashboard-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n}\n\n.heading {\n    margin-right: 100px;\n    margin-top: 50px;\n    font-size: 24px;\n    font-weight: bold;\n}\n\n.document-list {\n    margin-top: 20px;\n}\n\n.document-card {\n    background-color: #f2f2f2;\n    border-radius: 5px;\n    padding: 20px;\n    margin-bottom: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.document-card h3 {\n    margin-top: 0;\n    margin-bottom: 10px;\n    font-size: 25px;\n}\n\n.document-card .document-details {\n    display: flex;\n    flex-direction: column;\n}\n\n.document-card .expiry-date {\n    display: flex;\n    font-weight: bold;\n    color: #ff0000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
