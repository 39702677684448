import axios from 'axios';
import {BASEURL} from "../Constants";

class JobService {

    getJob(id) {
        return axios.get(`${BASEURL}/jobs/${id}`)
            .then(response => {
                return response.data;
            });
    }

    /**
     * Get all hours for a specific employee
     * @param {number} employeeId
     * @param {date} startDate
     * @param {date} endDate
     * @returns {Promise} All the hours for the employee
     * */
    getJobs(employeeId, startDate, endDate) {
        return axios.get(`${BASEURL}/jobs/week`, {
            params: {
                id: employeeId,
                startDate: startDate,
                endDate: endDate
            }
        })
            .then(response => {
                    console.log(response.data);
                    return response.data;
                }
            );
    }

    /**
     * Post a new hour for a specific employee
     * @param {number} employeeId
     * @param {date} date
     * @param {string} startTime
     * @param {string} endTime
     * @param {string} breakTime
     * @param {number} projectId
     * @param {string} location
     * @param {string} foreman
     * @returns {Promise} The new job
     */
    postJob(employeeId, date, startTime, endTime, breakTime, projectId, location, foreman) {
        const canEdit = true;

        // Fetch the Employee object by ID
        const employeeUrl = `${BASEURL}/employee/${employeeId}`;
        const employeePromise = axios.get(employeeUrl).then(response => response.data);

        // Fetch the Project object by ID
        const projectUrl = `${BASEURL}/project/${projectId}`;
        const projectPromise = axios.get(projectUrl).then(response => response.data);

        // Wait for both promises to resolve and include the Employee and Project objects in the request body
        return Promise.all([employeePromise, projectPromise]).then(([employee, project]) => {
            return axios.post(`${BASEURL}/jobs`, {
                employee,
                date,
                startTime,
                endTime,
                breakTime,
                project,
                location,
                foreman,
                canEdit
            })
                .then(response => response.data);
        });
    }

    updateJob(id, date, startTime, endTime, breakTime, project, location, foreman) {
        return axios.put(`${BASEURL}/jobs/${id}`, {
            date,
            startTime,
            endTime,
            breakTime,
            project,
            location,
            foreman
        })
            .then(response => response.data);
    }
}

export default new JobService();
