import * as React from 'react';
import NavBar from "../components/NavBar";
import "./EmployeeList.css";
import {ProSidebarProvider} from "react-pro-sidebar";
import {useState} from "react";
import {AiOutlineMenu} from "react-icons/ai";
import UserService from "../services/UserService";
import UserEditForm from "../components/UserEditForm";
import UserCreateForm from "../components/UserCreateForm";
import {useNavigate} from "react-router-dom";

const UserList = () => {
    const [users, setUsers] = React.useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [createUser, setCreateUser] = useState(null);
    const navigate = useNavigate();
    
    const handleUserEdit = (updatedUser) => {
        const updatedUsers = users.map((user) => {
            if (user.id === updatedUser.id) {
                return updatedUser;
            } else {
                return user;
            }
        });
        setUsers(updatedUsers);
        setSelectedUser('');
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleUserClick = (userId) => {
        setSelectedUser(userId);
    }

    const handleUserDelete = async (userId) => {
        // Confirm delete
        if (window.confirm("Are you sure you want to delete this user?")) {
            await UserService.deleteUser(userId);
            // remove user from state
            const updatedUsers = users.filter((user) => user.id !== userId);
            setUsers(updatedUsers);
        }
    }

    const handleUserCreate = () => {
        setCreateUser(true);
    }


    const handleFormClose = () => {
        setSelectedUser(null);
        setCreateUser(null);
    };

    const filteredUsers = users.filter((user) => {
        const fullName = `${user.name}`.toLowerCase();
        return fullName.includes(searchTerm.toLowerCase());
    });

    const fetchUsers = async () => {
        const userData = await UserService.getUsers();
        setUsers(userData);
    };

    React.useEffect(async () => {
        try {
            await fetchUsers();
        } catch (e) {
            console.log(e);
            navigate("/login");
        }

    }, []);

    return (
        <div>
            <ProSidebarProvider>
                <NavBar/>
            </ProSidebarProvider>
            <div className="employee-list" style={{width: "1200px"}}>
                <div className="search-container">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search employees..."
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                    <div className="employee-action-container">
                        <button className="employee-action-button"><AiOutlineMenu/></button>
                        <div className="employee-action-menu-content">
                            <button onClick={() => handleUserCreate()}>Create User</button>
                        </div>
                    </div>
                </div>
                <div className="employee-row employee-header">
                    <div className="employee-label">ID</div>
                    <div className="employee-label">Name</div>
                    <div className="employee-label">Admin</div>
                    <div className="employee-label">Last login</div>
                    <div className="employee-label">Logged in</div>
                    <p style={{'marginBottom': 12}}>Actions</p>
                </div>
                {filteredUsers.map((user, index) => (
                    <div className="employee-row" key={user.id}>
                        <div className="employee-value">{user.id}</div>
                        <div className="employee-value">{user.name}</div>
                        {user.admin && (
                            <div className="employee-value">Yes</div>
                        ) || (<div className="employee-value">No</div>)}
                        <div className="employee-value">{user.lastLogin}</div>
                        {user.loggedIn && (
                            <div className="employee-value">Yes</div>
                        ) || (<div className="employee-value">No</div>)}
                        <div className="employee-action-container">
                            <button className="employee-action-button"><AiOutlineMenu/></button>
                            <div className="employee-action-menu-content">
                                <button onClick={() => handleUserClick(user.id)}>Edit User</button>
                                <button onClick={() => handleUserDelete(user.id)}>Delete</button>
                            </div>
                        </div>
                    </div>

                ))}
            </div>
            {selectedUser && (
                <div className="employee-edit-popup">
                    <div className="employee-edit-popup-inner">
                        <UserEditForm user={users.find((user) => user.id === selectedUser)}
                                      onClose={handleFormClose} onSave={handleUserEdit}/>
                    </div>
                </div>
            )}
            {createUser && (
                <div className="employee-edit-popup">
                    <div className="employee-edit-popup-inner">
                        <UserCreateForm onClose={handleFormClose} onSave={handleUserEdit}/>
                    </div>
                </div>
            )}
        </div>
    )
}
export default UserList;
