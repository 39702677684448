class DocumentUtils {

    /**
     * Gets the full gender name from a gender character
     *
     * @param {string} genderCharacter the character to get the gender from
     * @returns {string} Gender name
     */
    static getGenderCode = (genderCharacter) => {
        if (genderCharacter === "f" || genderCharacter === "F") {
            return "female";
        } else if (genderCharacter === "m" || genderCharacter === "M") {
            return "male";
        } else {
            return "Other";
        }
    }



}

export default DocumentUtils;
