// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-left: 140px;
}

.employee-form {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 50px;
    float: left;
}

.employee-form:last-of-type {
    align-self: flex-start;
}

div {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
}

label {
    width: 150px;
    text-align: left;
    margin-right: 1rem;
}

input,
select {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    flex: 1 1;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"] {
    width: 100%;
    max-width: 500px;
}

select {
    width: 100%;
    max-width: 500px;
}

button[type="submit"] {
    padding: 0.5rem;
    border-radius: 4px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.submit-container {
    margin-top: 1rem;
    align-self: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmployeeForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;;IAEI,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,SAAO;AACX;;AAEA;;;;;;IAMI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,oBAAoB;AACxB","sourcesContent":[".row {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 20px;\n    margin-left: 140px;\n}\n\n.employee-form {\n    flex-direction: column;\n    align-items: flex-start;\n    margin-right: 50px;\n    float: left;\n}\n\n.employee-form:last-of-type {\n    align-self: flex-start;\n}\n\ndiv {\n    margin-bottom: 1rem;\n    display: flex;\n    flex-direction: row;\n}\n\nlabel {\n    width: 150px;\n    text-align: left;\n    margin-right: 1rem;\n}\n\ninput,\nselect {\n    padding: 0.5rem;\n    border-radius: 4px;\n    border: 1px solid #ccc;\n    flex: 1;\n}\n\ninput[type=\"date\"],\ninput[type=\"email\"],\ninput[type=\"number\"],\ninput[type=\"password\"],\ninput[type=\"tel\"],\ninput[type=\"text\"] {\n    width: 100%;\n    max-width: 500px;\n}\n\nselect {\n    width: 100%;\n    max-width: 500px;\n}\n\nbutton[type=\"submit\"] {\n    padding: 0.5rem;\n    border-radius: 4px;\n    border: none;\n    background-color: #007bff;\n    color: #fff;\n    cursor: pointer;\n}\n\n.submit-container {\n    margin-top: 1rem;\n    align-self: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
