import React, {useState} from 'react';
import './EditForm.css';
import SubcontractorService from "../services/SubcontractorService";

const SubcontractorEditForm = ({subcontractor, onEdit, onClose}) => {
    const [id, setId] = useState(subcontractor.id);
    const [name, setName] = useState(subcontractor.name);
    const [contactPerson, setContactPerson] = useState(subcontractor.contactPerson);
    const [phoneNumber, setPhoneNumber] = useState(subcontractor.phoneNumber);
    const [email, setEmail] = useState(subcontractor.email);
    const [wkaExpiryDate, setWkaExpiryDate] = useState(subcontractor.wkaExpiryDate);
    const [city, setCity] = useState(subcontractor.city);
    const [street, setStreet] = useState(subcontractor.street);
    const [houseNumber, setHouseNumber] = useState(subcontractor.houseNumber);
    const [postalCode, setPostalCode] = useState(subcontractor.postalCode);
    const [kvkNumber, setKvkNumber] = useState(subcontractor.kvkNumber);

    const handleSave = async () => {
        await SubcontractorService.updateSubcontractor(
            id, name, contactPerson, phoneNumber, email, wkaExpiryDate, city, street, houseNumber, postalCode, kvkNumber
        ).then(r => onEdit(r));


        // Close form
        onClose();
    };

    return (
        <div className="edit-form-overlay">
            <div className="edit-form">
                <div className="edit-form-row">
                    <label htmlFor="name">Name:</label>
                    <input
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="edit-form-input"
                    />
                </div>

                <div className="edit-form-row">
                    <label htmlFor="contactPerson">Contact Person:</label>
                    <input
                        id="name"
                        type="text"
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="phoneNumber">Phone Number:</label>
                    <input
                        id="phoneNumber"
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="wkaExpiryDate">WKA Expiry Date:</label>
                    <input
                        id="wkaExpiryDate"
                        type="date"
                        value={wkaExpiryDate}
                        onChange={(e) => setWkaExpiryDate(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="city">City:</label>
                    <input
                        id="city"
                        type="text"
                        value={city}
                        onChange={(e) => setWkaExpiryDate(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="street">Street:</label>
                    <input
                        id="street"
                        type="text"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="houseNumber">House number:</label>
                    <input
                        id="houseNumber"
                        type="text"
                        value={houseNumber}
                        onChange={(e) => setHouseNumber(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="postalCode">Postal Code:</label>
                    <input
                        id="postalCode"
                        type="text"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="kvkNumber">KvK Number:</label>
                    <input
                        id="kvkNumber"
                        type="text"
                        value={kvkNumber}
                        onChange={(e) => setKvkNumber(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-actions">
                    <button className="edit-form-save" onClick={handleSave}>
                        Save
                    </button>
                    <button className="edit-form-close" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubcontractorEditForm;
