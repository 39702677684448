import axios from 'axios';
import {BASEURL} from "../Constants";

class SubcontractorService {

    getSubcontractors() {
        return axios.get(`${BASEURL}/subcontractor`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.data;
            });
    }

    createSubcontractor(name, contactPerson, phoneNumber, email, wkaExpiryDate, city, street, houseNumber, postalCode, kvkNumber) {
        return axios.post(`${BASEURL}/subcontractor`, {
            name, contactPerson, phoneNumber, email, wkaExpiryDate, city, street, houseNumber, postalCode, kvkNumber
        },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                }
            }).then(response => {
                return response.data;
        });
    }

    getSubcontractor(id) {
        return axios.get(`${BASEURL}/subcontractor/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.data;
            });
    }

    updateSubcontractor(id, name, contactPerson, phoneNumber, email, wkaExpiryDate, city, street, houseNumber, postalCode, kvkNumber) {
        return axios.put(`${BASEURL}/subcontractor/${id}`, {
            id, name, contactPerson, phoneNumber, email, wkaExpiryDate, city, street, houseNumber, postalCode, kvkNumber
        },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                }
            }).then(response => {
            return response.data;
        })
    }
}
export default new SubcontractorService();
