import React, { useState, useEffect } from 'react';
import './DayPicker.css';
import DayEditForm from './DayEditForm';
import JobService from "../services/JobService";
import moment from "moment";

const DayPicker = ({ startDate, endDate }) => {
    const [selectedDay, setSelectedDay] = useState('');
    const [projects, setProjects] = useState([]);
    const [days, setDays] = useState([
        {
            id: 1,
            day: 'Maandag',
            date: startDate,
            startTime: '',
            endTime: '',
            breakTime: '',
            project: '',
            location: '',
            foreman: '',
        },
        {
            id: 2,
            day: 'Dinsdag',
            // add 1 day to startDate it's a string
            date: moment(startDate, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD"),
            startTime: '',
            endTime: '',
            break: '',
            projectName: '',
            location: '',
            foreman: '',
        },
        {
            id: 3,
            day: 'Woensdag',
            date: moment(startDate, "YYYY-MM-DD").add(2, 'days').format("YYYY-MM-DD"),
            startTime: '',
            endTime: '',
            break: '',
            project: '',
            location: '',
            foreman: '',
        },
        {
            id: 4,
            day: 'Donderdag',
            date: moment(startDate, "YYYY-MM-DD").add(3, 'days').format("YYYY-MM-DD"),
            startTime: '',
            endTime: '',
            break: '',
            projectName: '',
            location: '',
            foreman: '',
        },
        {
            id: 5,
            day: 'Vrijdag',
            date: moment(startDate, "YYYY-MM-DD").add(4, 'days').format("YYYY-MM-DD"),
            startTime: '',
            endTime: '',
            break: '',
            projectName: '',
            location: '',
            foreman: '',
        },
        {
            id: 6,
            date: moment(startDate, "YYYY-MM-DD").add(5, 'days').format("YYYY-MM-DD"),
            day: 'Zaterdag',
            startTime: '',
            endTime: '',
            break: '',
            projectName: '',
            location: '',
            foreman: '',
        },
        {
            id: 7,
            day: 'Zondag',
            date: endDate,
            startTime: '',
            endTime: '',
            break: '',
            projectName: '',
            location: '',
            foreman: '',
        },
    ]);

    useEffect(() => {
        async function fetchData() {
            const data = await JobService.getJobs(1, startDate, endDate); // TODO: get employeeId from session
            setProjects(data);
        }
        fetchData();
    }, [startDate, endDate]);

    useEffect(() => {
        const updatedDays = days.map((day) => {
            const projectData = projects.find((hour) => hour.date === day.date);
            if (projectData) {
                return {
                    id: day.id,
                    day: day.day,
                    // remove seconds from time
                    startTime: projectData.startTime.substring(0, projectData.startTime.length - 3),
                    endTime: projectData.endTime.substring(0, projectData.endTime.length - 3),
                    breakTime: projectData.breakTime.substring(0, projectData.breakTime.length - 3),
                    project: projectData.project.name,
                    location: projectData.location,
                    foreman: projectData.foreman,
                };
            } else {
                return day;
            }
        });
        setDays(updatedDays);
    }, [projects]);

    const handleDayClick = (day) => {
        setSelectedDay(day);
    };

    const handleDayEdit = (updatedDay) => {
        const updatedDays = days.map((day) => {
            if (day.day === updatedDay.day) {
                return updatedDay;
            } else {
                return day;
            }
        });
        setDays(updatedDays);
        setSelectedDay('');
    };

    const handleFormClose = () => {
        setSelectedDay('');
    };

    return (
        <div className="day-picker-container">
            <p className="day-picker-title">Kies een dag van de week en vul de uren in of pas ze aan:</p>
            <div className="day-picker-grid">
                {days.map((day) => (
                    <div
                        key={day.day}
                        className={`day-picker-box ${
                            day.day === selectedDay ? 'selected' : ''
                        }`}
                        onClick={() => handleDayClick(day.day)}
                    >
                        <p className="day-picker-day">{day.day}</p>
                        <div className="day-picker-details">
                            <p className="day-picker-time">
                                {day.startTime} - {day.endTime}
                            </p>
                            <p className="day-picker-project">{day.project}</p>
                            <p className="day-picker-location">{day.location}</p>
                        </div>
                    </div>
                ))}
            </div>
            {selectedDay && (
                <div className="day-edit-popup">
                    <div className="day-edit-popup-inner">
                        <DayEditForm day={days.find((day) => day.day === selectedDay)} onClose={handleFormClose} onEdit={handleDayEdit} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default DayPicker;
