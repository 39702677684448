import React, { useState } from 'react'
import DatePicker from 'rsuite/DatePicker';
import moment from 'moment';
import 'rsuite/dist/rsuite.min.css';
import './WeekPicker.css';
import DayPicker from "./DayPicker";

const WeekPicker = () => {

    const [objWeek, setObjWeek] = useState({
        date: new Date(),
        dateFrom: null,
        dateTo: null,
        weekNumber: null
    });

    const onChange = (date) => {
        const weekNumber = moment(date).isoWeek();
        const dateFrom = moment(date).startOf('isoWeek').toDate();
        const dateTo = moment(date).endOf('isoWeek').toDate();

        setObjWeek({
            date,
            dateFrom,
            dateTo,
            weekNumber
        })
    }

    const renderValue = (date) => {
        const weekNumber = moment(date).isoWeek();
        const year = moment(date).year();

        return `W${weekNumber}, ${year}`;
    }

    return (
        <div className='WeekPicker'>
            <h1>Week Picker</h1>

            <DatePicker
                placeholder='Week picker'
                isoWeek
                showWeekNumbers
                value={objWeek.date}
                onChange={onChange}
                renderValue={renderValue}
            />

            <div className='weekInfos'>
                <div>
                    <span>Week Nummer : </span>
                    <b>{objWeek.weekNumber}</b>
                </div>
                <div>
                    <span>Start van Week : </span>
                    <b>{objWeek.dateFrom?.toDateString()}</b>
                </div>
                <div>
                    <span>Einde van Week : </span>
                    <b>{objWeek.dateTo?.toDateString()}</b>
                </div>
            </div>
        {/*    If a week is selected show DayPicker and if one is not selected remove the DayPicker*/}
            {moment(objWeek.date).isValid() && objWeek.dateFrom && <DayPicker key={objWeek.weekNumber} startDate={moment(objWeek.dateFrom).format("YYYY-MM-DD")} endDate={moment(objWeek.dateTo).format("YYYY-MM-DD")}/>}
        </div>
    )
}

export default WeekPicker
