import * as React from 'react';
import NavBar from "../components/NavBar";
import EmployeeService from "../services/EmployeeService";
import "./EmployeeList.css";
import {ProSidebarProvider} from "react-pro-sidebar";
import {useState} from "react";
import EmployeeEditForm from "../components/EmployeeEditForm";
import StarterDocumentForm from "../components/StarterDocumentForm";
import {AiOutlineMenu} from "react-icons/ai";
import EmployeeView from "../components/EmployeeView";
import FileManager from "../components/FileManager";
import DocumentService from "../services/DocumentService";
import DocumentEditForm from "../components/DocumentEditForm";
import Utils from "../utils/Utils";
import {useNavigate} from "react-router-dom";

const EmployeeList = () => {
        const [employees, setEmployees] = React.useState([]);
        const [documents, setDocuments] = React.useState([]);
        const [selectedEmployeeToView, setSelectedEmployeeToView] = useState(null);
        const [selectedEmployee, setSelectedEmployee] = useState(null);
        const [selectedStartDocument, setSelectedStartDocument] = useState(null);
        const [selectedFileManager, setSelectedFileManager] = useState(null);
        const [selectedEditDocument, setSelectedEditDocument] = useState(null);
        const [documentFile, setDocumentFile] = useState(null);
        const [searchTerm, setSearchTerm] = useState("");
        const navigate = useNavigate();


        const handleSearchInputChange = (event) => {
            setSearchTerm(event.target.value);
        };

        const handleEmployeeEdit = (updatedEmployee) => {
            const updatedEmployees = employees.map((employee) => {
                if (employee.id === updatedEmployee.id) {
                    return updatedEmployee;
                } else {
                    return employee;
                }
            });
            setEmployees(updatedEmployees);
            setSelectedEmployee('');
        };

        const downloadAllEmployees = async () => {
            await DocumentService.getAllDocuments().then((response) => {
                setDocuments(response);
            });
            // Ask for confirmation
            const confirmed = window.confirm('Are you sure you want to download all employees?');

            if (!confirmed) {
                return;
            }
            const delimiter = ';';

            const headers = [
                'First Name',
                'Last Name',
                'Birth Date',
                'Country of Birth',
                'Nationality',
                'Gender',
                'BSN',
                'Email',
                'Telephone Number',
                'Document Number',
                'Expiry Date',
                'Date of Employment',
                'Hourly Wage',
                'Bank Account Number',
                'KVK Number',
                'Street',
                'House Number',
                'City',
                'Zipcode',
                'Subcontractor',
                'Company'
            ].join(delimiter) + '\n';

            const rows = documents.map(document => {
                return [
                    `"${document.employee.firstName}"`,
                    `"${document.employee.lastName}"`,
                    `"${document.employee.birthDate}"`,
                    `"${document.employee.countryOfBirth}"`,
                    `"${document.employee.nationality}"`,
                    `"${Utils.getGenderFromCode(document.employee.gender)}"`,
                    `"${document.employee.citizenServiceNumber}"`,
                    `"${document.employee.email}"`,
                    `"${document.employee.telephoneNumber}"`,
                    `"${document.documentNumber}"`,
                    `"${document.expiryDate}"`,
                    `"${document.employee.dateOfEmployment}"`,
                    `"${document.employee.hourlyWage}"`,
                    `"${document.employee.bankAccountNumber}"`,
                    `"${document.employee.kvkNumber}"`,
                    `"${document.employee.street}"`,
                    `"${document.employee.houseNumber}"`,
                    `"${document.employee.city}"`,
                    `"${document.employee.zipcode}"`,
                    `"${document.employee.subcontractor}"`,
                    `"${document.employee.company}"`,
                ].join(delimiter) + '\n';
            });

            const csvData = [
                headers,
                ...rows
            ].join('');

            // Rest of CSV generation code...

            const blob = new Blob([csvData], {type: 'text/csv'});

            // Create an object URL from the Blob
            const url = URL.createObjectURL(blob);

            // Create a download link and click it
            const link = document.createElement('a');
            link.href = url;
            link.download = 'employees.csv';
            link.click();
        }

        const handleEmployeeClick = (employeeId) => {
            setSelectedEmployee(employeeId);
        }

        const handleEmployeeView = (employeeId) => {
            setSelectedEmployeeToView(employeeId);
        }

        const handleStartDocument = (employeeId) => {
            setSelectedStartDocument(employeeId);
        }

        const handleFileManager = (employeeId) => {
            setSelectedFileManager(employeeId);
        }

        const handleDeleteEmployee = (employeeId) => {
            const confirmed = window.confirm('Are you sure you want to delete this employee?');
            const confirmed2 = window.confirm('Are you really sure?');
            if (!confirmed || !confirmed2) {
                return;
            }
            EmployeeService.deleteEmployee(employeeId).then(() => {
                setEmployees(employees.filter((employee) => employee.id !== employeeId));
            });
        };

        const handleEditDocument = (employeeId) => {

            DocumentService.getDocumentByEmployeeId(employeeId).then(response => {
                setSelectedEditDocument(response);
            });

        }

        const filteredEmployees = employees.filter((employee) => {
            const fullName = `${employee.firstName} ${employee.lastName}`.toLowerCase();
            return fullName.includes(searchTerm.toLowerCase());
        });

        const handleFormClose = () => {
            setSelectedEmployee(null);
            setSelectedStartDocument(null);
            setSelectedEmployeeToView(null);
            setSelectedFileManager(null);
            setSelectedEditDocument(null);
            setDocumentFile(null);
        };

        const fetchEmployees = async () => {
            const employeeData = await EmployeeService.getEmployees();
            setEmployees(employeeData);
        };
        React.useEffect(() => {
            try {
                fetchEmployees();
            } catch (e) {
                console.error(e);
                navigate('/login');
            }
        }, []);

        return (
            <div>
                <ProSidebarProvider>
                    <NavBar/>
                </ProSidebarProvider>
                <div className="employee-list" style={{width: "1200px"}}>
                    <div className="search-container">
                        <input
                            type="text"
                            className="search-input"
                            placeholder="Search employees..."
                            value={searchTerm}
                            onChange={handleSearchInputChange}
                        />
                        <div className="employee-action-container">
                            <button className="employee-action-button"><AiOutlineMenu/></button>
                            <div className="employee-action-menu-content">
                                <button onClick={() => downloadAllEmployees()}>Export Employee Info</button>
                            </div>
                        </div>
                    </div>
                    <div className="employee-row employee-header">
                        <div className="employee-label">ID</div>
                        <div className="employee-label">Name</div>
                        <div className="employee-label">Birthdate</div>
                        <div className="employee-label">Company</div>
                        <div className="employee-label">Subcontractor</div>
                        <div className="employee-label">Tel Nr</div>
                        <div className="employee-label">Email</div>
                        <p style={{'marginBottom': 12}}>Actions</p>
                    </div>
                    {filteredEmployees.map((employee, index) => (
                        <div className="employee-row" key={employee.id}>
                            <div className="employee-value">{employee.id}</div>
                            <div className="employee-value">{employee.firstName} {employee.lastName}</div>
                            <div className="employee-value">{employee.birthDate}</div>
                            <div className="employee-value">{employee.company}</div>
                            <div className="employee-value">{employee.subcontractor}</div>
                            <div className="employee-value">{employee.telephoneNumber}</div>
                            <div className="employee-value">{employee.email}</div>
                            <div className="employee-action-container">
                                <button className="employee-action-button"><AiOutlineMenu/></button>
                                <div className="employee-action-menu-content">
                                    <button onClick={() => handleEmployeeView(employee.id)}>View Employee Info</button>
                                    <button onClick={() => handleEmployeeClick(employee.id)}>Edit Employee Info</button>
                                    <button onClick={() => handleStartDocument(employee.id)}>Startersdocument</button>
                                    <button onClick={() => handleEditDocument(employee.id)}>Edit Document Info</button>
                                    <button onClick={() => handleFileManager(employee.id)}>View Employee Files</button>
                                    <button style={{ color: 'red' }} onClick={() => handleDeleteEmployee(employee.id)}>Delete Employee</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {selectedEmployeeToView && (
                    <div className="employee-view-popup">
                        <div className="employee-view-popup-inner">
                            <EmployeeView employee={employees.find((employee) => employee.id === selectedEmployeeToView)}
                                          onClose={handleFormClose}/>
                        </div>
                    </div>
                )}
                {selectedEmployee && (
                    <div className="employee-edit-popup">
                        <div className="employee-edit-popup-inner">
                            <EmployeeEditForm employee={employees.find((employee) => employee.id === selectedEmployee)}
                                              onClose={handleFormClose} onEdit={handleEmployeeEdit}/>
                        </div>
                    </div>
                )}
                {selectedStartDocument && (
                    <div className="starter-popup">
                        <div className="starter-popup-inner">
                            <StarterDocumentForm
                                person={employees.find((employee) => employee.id === selectedStartDocument)}
                                onClose={handleFormClose}/>
                        </div>
                    </div>
                )}
                {selectedFileManager && (
                    <div className="file-manager-popup">
                        <div className="file-manager-popup-inner">
                            <FileManager person={employees.find((employee) => employee.id === selectedFileManager)}
                                         onClose={handleFormClose}/>
                        </div>
                    </div>
                )}
                {selectedEditDocument && (
                    <div className="edit-document-popup">
                        <div className="edit-document-popup-inner">
                            <DocumentEditForm document={selectedEditDocument} documentFile={documentFile}
                                              onClose={handleFormClose}/>
                        </div>
                    </div>
                )}
            </div>
        );
    }
;

export default EmployeeList;
