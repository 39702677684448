import * as React from 'react';
import { Sidebar, Menu, MenuItem, menuClasses, SubMenu, useProSidebar } from 'react-pro-sidebar';
import {Users} from "../icons/users";
import {Cog} from "../icons/cog";
import {MenuIcon} from "../icons/menuIcon";
import { Link } from 'react-router-dom';
import {BiLogOut} from "react-icons/bi";
import {FaHome} from "react-icons/fa";
import AuthService from "../services/AuthService";


const NavBar = () => {
    // check if user has admin role
    const isAdmin = localStorage.getItem("isAdmin") === "true";
    // hex to rgba converter
    const hexToRgba = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };


    const [hasImage, setHasImage] = React.useState(false);
    const { toggleSidebar, collapseSidebar, broken, collapsed } = useProSidebar();

    const logout = () => {
        AuthService.logout();
    }
    const theme = {
        sidebar: {
            backgroundColor: '#ffffff',
            color: '#607489',
        },
            menu: {
                menuContent: '#fbfcfd',
                icon: '#0098e5',
                hover: {
                    backgroundColor: '#c5e4ff',
                    color: '#44596e',
                },
                disabled: {
                    color: '#9fb6cf',
                },
            },
    }

    const menuItemStyles = {
        root: {
            fontSize: '20px',
            fontWeight: 400,
        },
        icon: {
            color: theme.menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: theme.menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: '#b6b7b9',
            marginLeft: '60px', //  Adds space between icon and text

        },
        subMenuContent: ({ level }) => ({
            backgroundColor:
                level === 0
                    ? hexToRgba(theme.menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
                    : 'transparent',
        }),
        button: {
            [`&.${menuClasses.disabled}`]: {
                color: theme.menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: hexToRgba(theme.menu.hover.backgroundColor, hasImage ? 0.8 : 1),
                color: theme.menu.hover.color,
            },
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
        }),
    };

    return (
        <div>
            <Sidebar
                breakPoint="lg"
                backgroundColor={hexToRgba(theme.sidebar.backgroundColor, hasImage ? 0.9 : 1)}
                rootStyles={{
                    color: 607489,
                }}
            >
                <div>
                    <div>
                        <Menu menuItemStyles={menuItemStyles}>
                        <MenuItem icon={<FaHome />} component={<Link to="/" />}> Home </MenuItem>
                            <SubMenu label="Employees" icon={<Users />}>
                                <MenuItem component={<Link to="/createEmployee" />}> Add Employee</MenuItem>
                                <MenuItem component={<Link to="/employees" />}> View Employees</MenuItem>
                            </SubMenu>
                            {/*If user has admin role show admin panel*/}
                            {isAdmin &&
                            <SubMenu label="Admin Panel" icon={<Cog/>}>
                                <MenuItem component={<Link to="/users" />}>User settings</MenuItem>
                                <MenuItem component={<Link to="/subcontractor" />}>Subcontractors</MenuItem>
                                <MenuItem component={<Link to="/backup" />}>Backups</MenuItem>
                            </SubMenu>
                            }
                            <MenuItem icon={<BiLogOut />} onClick={logout} component={<Link to="/login" />}>Logout</MenuItem>
                        </Menu>
                    </div>
                </div>
            </Sidebar>
            <main>
            {/*If screen is not big enough to load sidebar show toggle button with Cog icon*/}
            {broken && ( <div style={{ position: 'absolute', top: '16px', left: '16px' }}>
                <button onClick={toggleSidebar} style={{ border: 'none', background: 'none' }}>
                    <MenuIcon />
                </button>
            </div>)}
            </main>
        </div>
    );
}
export default NavBar;
