import * as React from 'react';
import NavBar from "../components/NavBar";
import {ProSidebarProvider} from "react-pro-sidebar";
import UploadForm from "../components/UploadForm";

const AddEmployee = () => {
    return (
        <div>
            <ProSidebarProvider>
                <NavBar/>
            </ProSidebarProvider>
            <UploadForm/>
        </div>
    );
};

export default AddEmployee;
