import axios from 'axios';
import {BASEURL} from "../Constants";

class EmployeeService {
    getEmployees() {
        return axios.get(`${BASEURL}/employee`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            },
        })
            .then(response => {
                return response.data;
            });
    }

    createEmployee(firstName, lastName, birthDate, birthplace, countryOfBirth, nationality, gender,
                   citizenServiceNumber, email, telephoneNumber, dateOfEmployment, hourlyWage, bankAccountNumber,
                   kvkNumber, kvkExtractDate, subcontractor, company, submitterName, password, city, street, zipcode, houseNumber, outOfService) {
        return axios.post(`${BASEURL}/employee`, {
            firstName, lastName, birthDate, birthplace, countryOfBirth, nationality, gender,
            citizenServiceNumber, email, telephoneNumber, dateOfEmployment, hourlyWage, bankAccountNumber,
            kvkNumber, kvkExtractDate, subcontractor, company, submitterName, password, city, street, zipcode, houseNumber, outOfService
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            return response.data;
        });
    }

    getEmployee(id) {
        return axios.get(`${BASEURL}/employee/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.data;
            });
    }

    updateEmployee(id, firstName, lastName, insertion, birthDate, birthplace, countryOfBirth, nationality, gender,
                   citizenServiceNumber, email, telephoneNumber, dateOfEmployment, hourlyWage, bankAccountNumber,
                   kvkNumber, subcontractor, company, submitterName, password, street, houseNumber, city, zipcode) {
        return axios.put(`${BASEURL}/employee/${id}`, {
            firstName, lastName, insertion, birthDate, birthplace, countryOfBirth, nationality, gender,
            citizenServiceNumber, email, telephoneNumber, dateOfEmployment, hourlyWage, bankAccountNumber,
            kvkNumber, subcontractor, company, submitterName, password, street, houseNumber, city, zipcode
        }, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            return response.data;
        })
    }

    deleteEmployee(id) {
        return axios.delete(`${BASEURL}/employee/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            return response.data;
        })
    }
}
export default new EmployeeService();
