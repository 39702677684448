import * as React from 'react';
import NavBar from "../components/NavBar";
import "./EmployeeList.css";
import {ProSidebarProvider} from "react-pro-sidebar";
import {useState} from "react";
import {AiOutlineMenu} from "react-icons/ai";
import UserService from "../services/UserService";
import UserEditForm from "../components/UserEditForm";
import UserCreateForm from "../components/UserCreateForm";
import {useNavigate} from "react-router-dom";
import SubcontractorService from "../services/SubcontractorService";
import SubcontractorCreateForm from "../components/SubcontractorCreateForm";
import SubcontractorEditForm from "../components/SubcontractorEditForm";

const SubcontractorList = () => {
    const [subcontractors, setSubcontractors] = React.useState([]);
    const [selectedSubcontractor, setSelectedSubcontractor] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [createSubcontractor, setCreateSubcontractor] = useState(null);
    const navigate = useNavigate();
    
    const handleSubcontractorEdit = (updatedSubcontractor) => {
        const updatedSubcontractors = subcontractors.map((subcontractor) => {
            if (subcontractor.id === updatedSubcontractor.id) {
                return updatedSubcontractor;
            } else {
                return subcontractor;
            }
        });
        setSubcontractors(updatedSubcontractors);
        setSelectedSubcontractor('');
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSubcontractorClick = (userId) => {
        setSelectedSubcontractor(userId);
    }

    const handleSubcontractorCreate = () => {
        setCreateSubcontractor(true);
    }


    const handleFormClose = async () => {
        setSelectedSubcontractor(null);
        setCreateSubcontractor(null);
        await fetchSubcontractors();
    };

    const filteredSubcontractors = subcontractors.filter((subcontractor) => {
        const name = `${subcontractor.name}`.toLowerCase();
        return name.includes(searchTerm.toLowerCase());
    });

    const fetchSubcontractors = async () => {
        const subcontractorData = await SubcontractorService.getSubcontractors();
        setSubcontractors(subcontractorData);
    };

    React.useEffect(() => {
        try {
            fetchSubcontractors();
        } catch (e) {
            console.log(e);
            navigate("/login");
        }

    }, []);

    return (
        <div>
            <ProSidebarProvider>
                <NavBar/>
            </ProSidebarProvider>
            <div className="employee-list" style={{width: "1200px"}}>
                <div className="search-container">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search employees..."
                        value={searchTerm}
                        onChange={handleSearchInputChange}
                    />
                    <div className="employee-action-container">
                        <button className="employee-action-button"><AiOutlineMenu/></button>
                        <div className="employee-action-menu-content">
                            <button onClick={() => handleSubcontractorCreate()}>Create Subcontractor</button>
                        </div>
                    </div>
                </div>
                <div className="employee-row employee-header">
                    <div className="employee-label">ID</div>
                    <div className="employee-label">Name</div>
                    <div className="employee-label">Contactperson</div>
                    <div className="employee-label">Phone Number</div>
                    <div className="employee-label">Email</div>
                    <p style={{'marginBottom': 12}}>Actions</p>
                </div>
                {filteredSubcontractors.map((subcontractor, index) => (
                    <div className="employee-row" key={subcontractor.id}>
                        <div className="employee-value">{subcontractor.id}</div>
                        <div className="employee-value">{subcontractor.name}</div>
                        <div className="employee-value">{subcontractor.contactPerson}</div>
                        <div className="employee-value">{subcontractor.phoneNumber}</div>
                        <div className="employee-value">{subcontractor.email}</div>

                        <div className="employee-action-container">
                            <button className="employee-action-button"><AiOutlineMenu/></button>
                            <div className="employee-action-menu-content">
                                <button onClick={() => handleSubcontractorClick(subcontractor.id)}>Edit Subcontractor</button>
                                <button>Delete</button>
                            </div>
                        </div>
                    </div>

                ))}
            </div>
            {selectedSubcontractor && (
                <div className="employee-edit-popup">
                    <div className="employee-edit-popup-inner">
                        <SubcontractorEditForm subcontractor={subcontractors.find((subcontractor) => subcontractor.id === selectedSubcontractor)}
                                      onClose={handleFormClose} onEdit={handleSubcontractorEdit}/>
                    </div>
                </div>
            )}
            {createSubcontractor && (
                <div className="employee-edit-popup">
                    <div className="employee-edit-popup-inner">
                        <SubcontractorCreateForm onClose={handleFormClose} onSave={handleSubcontractorEdit}/>
                    </div>
                </div>
            )}
        </div>
    )
}
export default SubcontractorList;
