import React, {useState} from "react";
import {createWorker} from "tesseract.js";
import './UploadForm.css';
import EmployeeForm from "./EmployeeForm";
import {whereAlpha3} from 'iso-3166-1';
import Utils from "../utils/Utils";
import CountryUtils from "../utils/CountryUtils";
import DocumentUtils from "../utils/DocumentUtils";


const ImageToTextConverter = () => {
    const [documentImage, setDocumentImage] = useState("");
    const [documentFile, setDocumentFile] = useState("");
    const [text, setText] = useState("");
    const [doc, setDoc] = useState("");
    const [countryOfBirth, setCountryOfBirth] = useState("");
    const [docNumber, setDocNumber] = useState("");
    const [vNumber, setVNumber] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [gender, setGender] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [nationality, setNationality] = useState("");
    const [docType, setDocType] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setDocumentFile(file);
        const reader = new FileReader();
        reader.onload = () => {
            setDocumentImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const convertIdCardText = (text) => {
        const regex = /I<.*/m;
        const result = text.match(regex)[0];
        const lines = text.split('\n');
        const index = lines.indexOf(result);
        const nextTwoLines = lines.slice(index + 1, index + 3);
        const lineTwo = nextTwoLines[0];
        const lineThree = nextTwoLines[1].replace(/[0-9]/g, ''); // remove numbers from line 3 (Parsing error)
        console.log(result);
        console.log(lineTwo);
        console.log(lineThree);

        const pattern = /^([A|C|I][A-Z0-9<]{1})([A-Z]{3})([A-Z0-9<]{9})([0-9]{1})([A-Z0-9<]{15})$/;

        const match = result.match(pattern)

        if (match) {
            const [full, doc, country, docNumber, hash, vNumber] = match;
            console.log(full);
            console.log(doc);
            setDocType("ID Card");
            console.log(country);
            console.log(docNumber);
            setDocNumber(docNumber);
            console.log(hash);
            console.log(vNumber);
        } else {
            console.log("Invalid MRZ format");
        }

        const secondLinePat = /^([0-9]{6})([0-9]{1})([M|F|X|<]{1})([0-9]{6})([0-9]{1})([A-Z]{3})([A-Z0-9<]{11})([0-9]{1})$/;
        const secondLineIdMatch = lineTwo.match(secondLinePat)
        if (secondLineIdMatch) {
            const [full2, birthDate, hash2, gender, expiryDate, hash3, nationality, optionalData] = secondLineIdMatch;

            console.log("birthDate:", birthDate);
            console.log(hash2);
            setBirthDate(Utils.convertDate(birthDate));
            setGender(gender);
            console.log("expiryDate:", expiryDate);
            setExpiryDate(Utils.convertDate(expiryDate));
            console.log(nationality);
            setNationality(CountryUtils.getNationality(nationality));
            console.log(optionalData);
        } else {
            console.log("Invalid MRZ format");
        }
        const thirdLinePattern = /^([A-Z0-9<]{30})$/;
        const thirdLineMatch = lineThree.match(thirdLinePattern)
        console.log(thirdLineMatch)
        if (thirdLineMatch) {
            const [full3, name] = thirdLineMatch;
            // if there is only 1 < then it is last name
            // if there are 2 < then it is first name
            const nameParts = name.split("<<");
            console.log("nameParts:", nameParts);
            const lastName = nameParts[0].toString().replace("<", " ");

            console.log("Last Name:", lastName);
            const firstName = nameParts[1].toString().replace("<", " ").replace("<", " ");
            console.log("First Name:", firstName);
            setLastName(Utils.capitalizeWords(lastName));
            setFirstName(Utils.capitalizeWords(firstName));
        } else {
            console.log("Invalid MRZ format Line3");
        }
    }

    const convertResidencePermitText = (text) => {
        // Filter MRZ lines using a regular expression
        // regex but get everything
        const regex = /ITNL.*/m;
        const result = text.match(regex)[0];
        const lines = text.split('\n');
        const index = lines.indexOf(result);
        const nextTwoLines = lines.slice(index + 1, index + 3);
        const lineTwo = nextTwoLines[0];
        const lineThree = nextTwoLines[1].replace(/[0-9]/g, ''); // remove numbers from line 3 (Parsing error)
        console.log(result);
        console.log(lineTwo);
        console.log(lineThree);
        setText(result);

        const pattern = /^([A|C|I][A-Z0-9<]{1})([A-Z]{3})([A-Z0-9<]{9})([0-9]{1})([A-Z0-9<]{15})$/;

        const match = result.match(pattern)

        console.log(match)

        if (match) {
            const [full, doc, country, docNumber, hash, vNumber] = match;

            console.log("doc:", doc);
            console.log("country of birth:", country);
            console.log("docNumber:", docNumber);
            console.log("VNumber:", vNumber.replace("<<", ""));
            console.log("hash:", hash);
            setDoc(doc);
            setDocType("Residence Permit");
            setDocNumber(docNumber);
            setVNumber(vNumber.replace("<<", ""));
        } else {
            console.log("Invalid MRZ format");
        }
        const secondLinePattern = /^([0-9]{6})([0-9]{1})([M|F|X|<]{1})([0-9]{6})([0-9]{1})([A-Z]{3})([A-Z0-9<]{11})([0-9]{1})$/;
        const secondLineMatch = lineTwo.match(secondLinePattern)
        if (secondLineMatch) {
            const [full2, birthDate, hash2, gender, expiryDate, hash3, nationality, optionalData] = secondLineMatch;
            console.log("birthDate:", birthDate);
            console.log("hash2:", hash2);
            console.log("Gender: ", gender);
            console.log("Expiration date:", expiryDate);
            console.log("hash3:", hash3);
            console.log("Nationality:", nationality);
            console.log("DocType", optionalData);
            setBirthDate(Utils.convertDate(birthDate));
            setGender(gender);
            setExpiryDate(Utils.convertDate(expiryDate));
            setNationality(CountryUtils.getNationality(nationality));
        } else {
            console.log("Invalid MRZ format Line2");
        }
        const thirdLinePattern = /^([A-Z0-9<]{30})$/;
        const thirdLineMatch = lineThree.match(thirdLinePattern)
        console.log(thirdLineMatch)
        if (thirdLineMatch) {
            const [full3, name] = thirdLineMatch;
            // if there is only 1 < then it is last name
            // if there are 2 < then it is first name
            const nameParts = name.split("<<");
            console.log("nameParts:", nameParts);
            const lastName = nameParts[0].toString().replace("<", " ");

            console.log("Last Name:", lastName);
            const firstName = nameParts[1].toString().replace("<", " ").replace("<", " ");
            console.log("First Name:", firstName);
            setLastName(Utils.capitalizeWords(lastName));
            setFirstName(Utils.capitalizeWords(firstName));
        } else {
            console.log("Invalid MRZ format Line3");
        }
    }


    const convertPassportText = (text) => {
        const regex = /P<.*/m;
        const result = text.match(regex)[0];
        const lines = text.split('\n');
        const index = lines.indexOf(result);
        const nextTwoLines = lines.slice(index + 1, index + 3);
        const lineTwo = nextTwoLines[0];
        console.log(result);
        console.log(lineTwo);

        // pattern = (P[A-Z0-9<]{1})([A-Z]{3})([A-Z0-9<]{39})
        const pattern = /^([P|C|I][A-Z0-9<]{1})([A-Z]{3})([A-Z0-9<]{39})$/;
        const match = result.match(pattern)
        console.log(match)
        if (match) {
            let [full, doc, country, name] = match;
            console.log("doc:", doc);
            console.log("country:", country);
            console.log("name:", name);
            setDocType("Passport");
            setDoc(doc.replace("<", " "));
            setCountryOfBirth(whereAlpha3(country).country);
            console.log(countryOfBirth);
            let nameParts = name.split("<<");
            let lastName = nameParts[0].toString().replace("<", " ");
            setLastName(Utils.capitalizeWords(lastName));
            let firstName = nameParts[1].toString().replace("<", " ").replace("<", " ");
            setFirstName(Utils.capitalizeWords(firstName));


        }
        // pattern = ([A-Z0-9<]{9})([0-9]{1})([A-Z]{3})([0-9]{6})([0-9]{1})([M|F|X|<]{1})([0-9]{6})([0-9]{1})([A-Z0-9<]{14})([0-9]{1})([0-9]{1})
        const secondLinePattern = /^([A-Z0-9<]{9})([0-9]{1})([A-Z]{3})([0-9]{6})([0-9]{1})([M|F|X|<]{1})([0-9]{6})([0-9]{1})([A-Z0-9<]{14})([0-9]{1})([0-9]{1})$/;
        const secondLineMatch = lineTwo.match(secondLinePattern)

        if (secondLineMatch) {
            let [full, docNumber, hash, nationality, birthDate, hash2, gender, expiryDate, hash3, personalNumber] = secondLineMatch;
            console.log("docNumber:", docNumber);
            setDocNumber(docNumber)

            setNationality(CountryUtils.getNationality(nationality));
            console.log("birthDate:", birthDate);
            setBirthDate(Utils.convertDate(birthDate));
            console.log("gender:", gender);
            setGender(gender);
            console.log("expiryDate:", expiryDate);
            setExpiryDate(Utils.convertDate(expiryDate));
            console.log("personalNumber:", personalNumber);
        }
    }
    const convertImageToText = async (imageSrc) => {
        let completed = false;
        setIsLoading(true);
        const worker = await createWorker({
            langPath: 'http://37.97.146.231:3000/',
            langs: ['mrz'],
        });
        await worker.loadLanguage('mrz');
        await worker.initialize('mrz');
        const {data: {text}} = await worker.recognize(imageSrc);
        await worker.terminate();
        console.log(text)

        try {
            convertResidencePermitText(text);
            completed = true;
        } catch (e) {
            console.log("Not a residence permit");
        }
        try {
            convertPassportText(text);
            completed = true;
        } catch (e) {
            console.log("Not a passport");
        }
        try {
            convertIdCardText(text);
            completed = true;
        } catch (e) {
            console.log("Not an ID card");
        }
        setIsLoading(false);
        setIsLoaded(true);
        if (!completed) {
            alert("Could not read the document. Please fill in the details manually.");
        }


    };

    return (
        <div className="upload-form-container">
            {!isLoaded && (
                <>
                    <input type="file" onChange={handleImageChange}/>
                    <button onClick={() => convertImageToText(documentImage)}>Submit</button>
                    {isLoading && <div className="loading">Loading...</div>}
                    {documentImage && <img src={documentImage} alt="preview" className="image"/>}
                </>
            )}
            {isLoaded && <EmployeeForm firstName={firstName} lastName={lastName} birthDate={birthDate}
                                       countryOfBirth={countryOfBirth} nationality={nationality}
                                       gender={DocumentUtils.getGenderCode(gender)} expiryDate={expiryDate}
                                       documentType={docType} documentNumber={docNumber} editMode={false} image={documentFile}/>}
        </div>
    );
};

export default ImageToTextConverter;
