import axios from "axios";
import {BASEURL} from "../Constants";
class DocumentService {
    
    createDocument(documentNumber, documentType, expiryDate, dateOfIssue, employee) {
        return axios.post(`${BASEURL}/document`, {
            documentNumber, documentType, expiryDate, dateOfIssue, employee
        },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                }
            }).then(response => {
            console.log(response.data);
            return response.data;
        });
    }

    updateDocument(id, documentNumber, documentType, expiryDate, dateOfIssue, employee) {
        return axios.put(`${BASEURL}/document/${id}`, {
            documentNumber, documentType, expiryDate, dateOfIssue, employee
        },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                }
            }).then(response => {
            return response.data;
        });
    }

    updateDocumentImage(id, file) {
        const formData = new FormData();
        formData.append('file', file);
        return axios.put(`${BASEURL}/document/image/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": "Bearer " + localStorage.getItem("token"),
            },
        }).then(response => {
            return response.data;
        });
    }

    uploadDocument(employeeId, file) {
        const formData = new FormData();
        formData.append('file', file);
        console.log(file);
        return axios.post(`${BASEURL}/document/${employeeId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": "Bearer " + localStorage.getItem("token"),
            },
        })
            .then((response) => {
                console.log('File uploaded successfully');
                return response.data;
            })
            .catch((error) => {
                console.error('Error uploading file:', error);
                // Handle error condition
            });
    }

    getDocumentByEmployeeId(employeeId) {
        return axios.get(`${BASEURL}/document/employee/${employeeId}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            return response.data;
        });
    }

    getExpiringDocuments() {
        return axios.get(`${BASEURL}/document/expiring`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            return response.data;
        });
    }

    getAllDocuments() {
        return axios.get(`${BASEURL}/document`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            console.log(response.data);
            return response.data;
        });
    }

} export default new DocumentService();
