import React, {useState} from 'react';
import './EmployeeForm.css';
import EmployeeService from "../services/EmployeeService";
import DocumentService from "../services/DocumentService";
import { useNavigate } from 'react-router-dom';
import SubcontractorService from "../services/SubcontractorService";

function EmployeeForm(props) {
    const [employee, setEmployee] = useState(null);
    const navigate = useNavigate();
    const editMode = props.editMode || false;
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    // load image from props.image
    const [image, setImage] = useState(props.image || null);
    const [subcontractors, setSubcontractors] = useState([]);

    React.useEffect( () => {
        const fetchSubcontractors = async () => {
            const subcontractorData = await SubcontractorService.getSubcontractors();
            setSubcontractors(subcontractorData);
        };
        try {
            fetchSubcontractors();
        } catch (e) {
            console.log(e);
            navigate("/login");
        }
    }, []);

    const [formData, setFormData] = useState({
        firstName: props.firstName || '',
        lastName: props.lastName || '',
        birthDate: props.birthDate || '',
        birthplace: props.birthplace || '',
        countryOfBirth: props.countryOfBirth || '',
        nationality: props.nationality || '',
        gender: props.gender || '',
        citizenServiceNumber: props.citizenServiceNumber || '',
        email: props.email || '',
        telephoneNumber: props.telephoneNumber || '',
        dateOfEmployment: props.dateOfEmployment || '',
        hourlyWage: props.hourlyWage || '',
        bankAccountNumber: props.bankAccountNumber || '',
        kvkNumber: props.kvkNumber || '',
        kvkExtractDate: props.kvkExtractDate || '',
        subcontractor: props.subcontractor || '',
        submitterName: props.submitterName || '',
        password: props.password || '',
        documentType: props.documentType || '',
        dateOfIssue: props.dateOfIssue || '',
        expiryDate: props.expiryDate || '',
        documentNumber: props.documentNumber || '',
        city: props.city || '',
        street: props.street || '',
        zipcode: props.zipcode || '',
        houseNumber: props.houseNumber || '',
        company: props.company || '',
    });


    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const createEmployee = async () => {
        const {
            firstName, lastName, birthDate, birthplace, countryOfBirth,
            nationality, gender, citizenServiceNumber, email, telephoneNumber, dateOfEmployment,
            hourlyWage, bankAccountNumber, kvkNumber, kvkExtractDate, subcontractor, company, submitterName, password, street, zipcode, houseNumber, city
        } = formData;
        // Check if all fields are filled in
        const allFieldsFilled = Object.keys(formData).filter(key => key !== 'kvkNumber' && key !== 'password' && key !== 'kvkExtractDate' && key !== 'subcontractor' && key !== 'bankAccountNumber').every((key) => formData[key] !== '');
        if (!allFieldsFilled) {
            alert('Please fill in all required fields');
            return;
        }

        // Check if citizenServiceNumber and kvkNumber are integers
        if (!Number.isInteger(Number(citizenServiceNumber))) {
            alert('BSN number must be an integer');
            return;
        }
        if (!Number.isInteger(Number(kvkNumber))) {
            alert('KVK number must be an integer');
            return;
        }

        if (!Number.isInteger(Number(telephoneNumber))) {
            alert('telephone number must be an integer');
            return;
        }

        // Check if expiryDate is still valid for a month
        const today = new Date();
        const expiryDate = new Date(formData.expiryDate);
        if (expiryDate < today) {
            alert('Document has expired, please upload a different document');
            return;
        }
        if (expiryDate < new Date(today.setMonth(today.getMonth() + 1))) {
            alert('Document will expire within a month, please upload a different document as soon as possible');
        }

        // Convert gender to byte
        let genderByte;
        if (gender === "male") {
            genderByte = 1;
        } else if (gender === "female") {
            genderByte = 2;
        } else {
            genderByte = 3;
        }


        // Submit the form
        const employeeRequest = await EmployeeService.createEmployee(firstName, lastName, birthDate, birthplace, countryOfBirth,
            nationality, genderByte, citizenServiceNumber, email, telephoneNumber, dateOfEmployment, hourlyWage,
            bankAccountNumber, kvkNumber, kvkExtractDate, subcontractor, company, submitterName, password, city, street, zipcode, houseNumber, false)
            .then((employee) => {
                setEmployee(employee);
                createDocument(employee);
                return employee;
            }).catch((error) => {
                console.log(error);
            });
    }

    const createDocument = async (employee) => {
        const {documentType, dateOfIssue, documentNumber, expiryDate} = formData;
        let documentTypeByte;
        if (documentType === "Passport") {
            documentTypeByte = 0;
        } else if (documentType === "ID Card") {
            documentTypeByte = 1;
        } else { // Residence Permit
            documentTypeByte = 2;
        }
        const document = await DocumentService.createDocument(documentNumber, documentTypeByte, expiryDate, dateOfIssue, employee);
        const documentFile = await DocumentService.uploadDocument(employee.id, image);
        navigate("/employees")
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Disable the button
        setButtonDisabled(true);

        // Enable the button after 10 seconds
        setTimeout(() => {
            setButtonDisabled(false);
        }, 10000); // 10 seconds in milliseconds

        await createEmployee();
    };

    return (
        <form onSubmit={handleSubmit} >
            <div className="row">
                <div className="employee-form">
                    <h1>Employee info</h1>
                    <div>
                        <label htmlFor="firstName">First Name:</label>
                        <input type="text" id="firstName" name="firstName" value={formData.firstName}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="lastName">Last Name:</label>
                        <input type="text" id="lastName" name="lastName" value={formData.lastName}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="birthdate">Birthdate:</label>
                        <input type="date" id="birthDate" name="birthDate" value={formData.birthDate}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="birthplace">Birthplace:</label>
                        <input type="text" id="birthplace" name="birthplace" value={formData.birthplace}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="countryOfBirth">Country of Birth:</label>
                        <input type="text" id="countryOfBirth" name="countryOfBirth" value={formData.countryOfBirth}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="nationality">Nationality:</label>
                        <input type="text" id="nationality" name="nationality" value={formData.nationality}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="gender">Gender:</label>
                        <select id="gender" name="gender" value={formData.gender} onChange={handleChange}>
                            <option value="">--Select--</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="citizenServiceNumber">BSN Number:</label>
                        <input type="text" id="citizenServiceNumber" name="citizenServiceNumber"
                               value={formData.citizenServiceNumber} onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="dateOfEmployment">Date of Employment:</label>
                        <input type="date" id="dateOfEmployment" name="dateOfEmployment"
                               value={formData.dateOfEmployment}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="hourlyWage">Hourly Wage:</label>
                        <input type="number" id="hourlyWage" name="hourlyWage" value={formData.hourlyWage}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="bankAccountNumber">Bank Account Number:</label>
                        <input type="text" id="bankAccountNumber" name="bankAccountNumber"
                               value={formData.bankAccountNumber}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="kvkNumber">KVK Number:</label>
                        <input type="text" id="kvkNumber" name="kvkNumber" value={formData.kvkNumber}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="kvkExtractDate">Datum KvK Uitreksel:</label>
                        <input type={"date"} id={"kvkExtractDate"} name={"kvkExtractDate"} value={formData.kvkExtractDate}
                                 onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="subcontractor">Subcontractor:</label>
                        <select id="subcontractor" name="subcontractor" value={formData.subcontractor} onChange={handleChange}>
                            <option value="">--Select--</option>
                            {subcontractors.map((subcontractor) => (
                                <option value={subcontractor.name}>{subcontractor.name}</option>
                            ))}
                        </select>

                    </div>
                    <div>
                        <label htmlFor="company">Company:</label>
                        <select id="company" name="company" value={formData.company} onChange={handleChange}>
                            <option value="">--Select--</option>
                            <option value="Crown">Crown</option>
                            <option value="North-Star">North-Star</option>
                            <option value="Oost-West">Oost-West</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="submitterName">Submitter Name:</label>
                        <input type="text" id="submitterName" name="submitterName" value={formData.submitterName}
                               onChange={handleChange}/>
                    </div>
                    {/*<div>*/}
                    {/*    <label htmlFor="password">Password:</label>*/}
                    {/*    <input type="password" id="password" name="password" value={formData.password}*/}
                    {/*           onChange={handleChange}/>*/}
                    {/*</div>*/}
                    <div className="submit-container">
                        <button type="submit">Submit</button>
                    </div>
                </div>
                <div className="employee-form" style={{margin: '10px'}}>
                    <h1>{formData.documentType} Info</h1>
                    <div>
                        <label htmlFor="documentType">Document Type:</label>
                        <select id="documentType" name="documentType" value={formData.documentType} onChange={handleChange}>
                            <option value="">--Select--</option>
                            <option value="Passport">Passport</option>
                            <option value="ID Card">ID Card</option>
                            <option value="Residence Permit">Residence Permit</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="dateOfIssue">Date of issue:</label>
                        <input type="date" id="dateOfIssue" name="dateOfIssue" value={formData.dateOfIssue}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="expiryDate">Expiry date:</label>
                        <input type="date" id="expiryDate" name="expiryDate" value={formData.expiryDate}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="documentNumber">Document number:</label>
                        <input type="text" id="documentNumber" name="documentNumber" value={formData.documentNumber}
                               onChange={handleChange}/>
                    </div>
                    <h1>Address info</h1>
                    <div>
                        <label htmlFor="street">Street name:</label>
                        <input type="text" id="street" name="street" value={formData.street}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="houseNumber">House number:</label>
                        <input type="text" id="houseNumber" name="houseNumber" value={formData.houseNumber}
                               onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="zipcode">Postcode:</label>
                        <input type="text" id="zipcode" name="zipcode" value={formData.zipcode}
                               onChange={handleChange}/>

                    </div>
                    <div>
                        <label htmlFor="city">City:</label>
                        <input type="text" id="city" name="city" value={formData.city}
                               onChange={handleChange}/>
                    </div>
                    <h1>Contact info</h1>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange}/>
                    </div>
                    <div>
                        <label htmlFor="telephoneNumber">Telephone Number:</label>
                        <input type="tel" id="telephoneNumber" name="telephoneNumber" value={formData.telephoneNumber}
                               onChange={handleChange}/>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default EmployeeForm;
