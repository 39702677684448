// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employee-info-container {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}
.employee-info-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
    text-align: center;
}

.employee-info-row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.employee-info-form {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 100%;
    padding: 24px;
    flex-direction: column;
}

.employee-info-label {
    flex: 0 0 120px;
    margin-right: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/EmployeeView.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,cAAc;IACd,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,wCAAwC;IACxC,YAAY;IACZ,eAAe;IACf,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".employee-info-container {\n    position: fixed;\n    z-index: 999;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n    background-color: rgba(0, 0, 0, 0.4);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.employee-info-title {\n    font-size: 24px;\n    font-weight: bold;\n    margin-bottom: 24px;\n    text-align: center;\n}\n\n.employee-info-row {\n    display: flex;\n    align-items: center;\n    margin-bottom: 16px;\n}\n\n.employee-info-form {\n    background-color: #fff;\n    border-radius: 8px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    width: 400px;\n    max-width: 100%;\n    padding: 24px;\n    flex-direction: column;\n}\n\n.employee-info-label {\n    flex: 0 0 120px;\n    margin-right: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
