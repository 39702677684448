import React, {useState} from 'react';
import './EditForm.css';
import SubcontractorService from "../services/SubcontractorService";

const SubcontractorCreateForm = ({onSave, onClose}) => {
    const [name, setName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [wkaExpiryDate, setWkaExpiryDate] = useState("");
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [kvkNumber, setKvkNumber] = useState("");

    const handleSave = async () => {
        // Check if all fields are filled
        if (name === "" || contactPerson === "" || phoneNumber === "" || email === "" || wkaExpiryDate === "" || city === "" || street === "" || houseNumber === "" || postalCode === "" || kvkNumber === "") {
            alert("Please fill all fields!");
            return;
        }
        await SubcontractorService.createSubcontractor(
            name, contactPerson, phoneNumber, email, wkaExpiryDate, city, street, houseNumber, postalCode, kvkNumber).then(r => onSave(r));

        onClose();
    };

    return (
        <div className="edit-form-overlay">
            <div className="edit-form">
                <div className="edit-form-row">
                    <label htmlFor="name">Name:</label>
                    <input
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="contactPerson">Contact person:</label>
                    <input
                        id="contactPerson"
                        type="text"
                        value={contactPerson}
                        onChange={(e) => setContactPerson(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="phoneNumber">Phone number::</label>
                    <input
                        id="phoneNumber"
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="email">Email:</label>
                    <input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="wkaExpiryDate">WKA Expiration Date:</label>
                    <input
                        id="wkaExpiryDate"
                        type="date"
                        value={wkaExpiryDate}
                        onChange={(e) => setWkaExpiryDate(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="city">City:</label>
                    <input
                        id="city"
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="street">Street:</label>
                    <input
                        id="street"
                        type="text"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="houseNumber">House number:</label>
                    <input
                        id="houseNumber"
                        type="text"
                        value={houseNumber}
                        onChange={(e) => setHouseNumber(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="postalCode">Postal code:</label>
                    <input
                        id="postalCode"
                        type="text"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="kvkNumber">KvK Number:</label>
                    <input
                        id="kvkNumber"
                        type="text"
                        value={kvkNumber}
                        onChange={(e) => setKvkNumber(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-actions">
                    <button className="edit-form-save" onClick={handleSave}>
                        Create
                    </button>
                    <button className="edit-form-close" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubcontractorCreateForm;
