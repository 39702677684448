import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthService from "../services/AuthService";
import {useNavigate} from "react-router-dom";

const theme = createTheme();

export default function Login() {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = React.useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get('username');
        const password = data.get('password');
        console.log(username, password)
        AuthService.login(username, password)
            .then((response) => {
                console.log(response);
                console.log("Login successful");
                navigate("/");
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage("Incorrect email or password");
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100%',
                            bgcolor: 'background.paper',
                            boxShadow: 1,
                            p: 4,
                        }}
                    >
                        <Avatar sx={{ m: 1}}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                Sign In
                            </Button>
                            {errorMessage &&
                                <Box sx={{ mt: 1 }}>
                                    <Typography variant="body2" color="error">
                                        {errorMessage}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
