import * as React from 'react';
import {useEffect} from 'react';
import NavBar from "../components/NavBar";
import {ProSidebarProvider} from "react-pro-sidebar";
import DocumentService from "../services/DocumentService";
import "./Dashboard.css"
import {useNavigate} from "react-router-dom";

const Dashboard = () => {
    const [expiringDocuments, setExpiringDocuments] = React.useState([]);
    const navigate = useNavigate();

    const getKvkExpireDate = (date) => {
        if (date === null) return ("No Kvk Extract Date found");
        const dateParts = date.split("-");
        const newDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        // Add 1 year to the date
        newDate.setFullYear(newDate.getFullYear() + 1);
        return newDate.toDateString();
    }

    const getExpiringIdString = (date) => {
        const dateParts = date.split("-");
        const newDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
        return newDate.toDateString();
    }

    useEffect(() => {

        DocumentService.getExpiringDocuments().then(response => {
            setExpiringDocuments(response);
        }).catch((error) => {
            console.log(error);
            navigate("/login");
        });

    }, []);

    return (
        <div>
            <ProSidebarProvider>
                <NavBar/>
            </ProSidebarProvider>
            <div className="dashboard-container">
                <div className="heading">Actions needed</div>
                <div className="document-list">
                    {expiringDocuments.map((document) => (
                        <div className="document-card" key={document.id}>
                            <h3>{document.documentType}. {document.employee.firstName} {document.employee.lastName}</h3>
                            <p>
                                <span className="expiry-date">Identity Document Expiring on:</span> {getExpiringIdString(document.expiryDate)}
                                <span className="expiry-date">Kvk Uitreksel expiring on:</span> {getKvkExpireDate(document.employee.kvkExtractDate)}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default Dashboard;
