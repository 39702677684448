import React, {useState, useEffect, useRef} from 'react';
import './DocumentEditForm.css';
import './EditForm.css'
import EmployeeService from "../services/EmployeeService";
import DocumentService from "../services/DocumentService";

const DocumentEditForm = ({isOpen, onClose, document, documentFile}) => {
    const [documentNumber, setDocumentNumber] = useState(document.documentNumber);
    const [dateOfIssue, setDateOfIssue] = useState(document.dateOfIssue);
    const [expiryDate, setExpiryDate] = useState(document.expiryDate);
    const [documentType, setDocumentType] = useState(document.documentType);
    const [image, setImage] = useState(document.image);


    const handleSave = () => {
        DocumentService.updateDocument(document.id, documentNumber, documentType, expiryDate, dateOfIssue, document.employee).then(r => {
            onClose();
        });
    };

    const fileInputRef = useRef(null);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            DocumentService.updateDocumentImage(document.id, file).then(r => {
                setImage(r.image);
            });
        }
    };
    return (
        <div className="edit-form-overlay">
            <div className="left">
                <div>
                    <img src={`data:image/png;base64,${image}`} alt={"document"} className="document-image"/>
                </div>
                <button className="replace-document-button" onClick={() => fileInputRef.current.click()}>Replace Document</button>
                <input type="file" accept="image/*" onChange={handleImageChange} ref={fileInputRef} style={{ display: "none" }}/>
            </div>
            <div className="edit-form">
                <div className="edit-form-row">
                    <label htmlFor="document-number">Document Number:</label>
                    <input
                        id="document-number"
                        type="text"
                        value={documentNumber}
                        onChange={(e) => setDocumentNumber(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="date-of-issue">Date of issue:</label>
                    <input
                        id="date-of-issue"
                        type="date"
                        value={dateOfIssue}
                        onChange={(e) => setDateOfIssue(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="expiry-date">Expiry date:</label>
                    <input
                        id="expiry-date"
                        type="date"
                        value={expiryDate}
                        onChange={(e) => setExpiryDate(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                <div className="edit-form-row">
                    <label htmlFor="document-type">Document type:</label>
                    <select
                        id="documentType"
                        value={documentType}
                        onChange={(e) => setDocumentType(e.target.value)}
                        className="edit-form-input"
                    >
                        <option value='0'>
                            Passport
                        </option>
                        <option value='1'>
                            Identity Card
                        </option>
                        <option value='2'>
                            Residence Permit
                        </option>
                    </select>
                </div>
                <div className="edit-form-actions">
                    <button className="edit-form-save" onClick={handleSave}>
                        Save
                    </button>
                    <button className="edit-form-close" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DocumentEditForm;
