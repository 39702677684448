import React, {useState, useEffect} from 'react';
import FileService from "../services/FileService";
import "./FileManager.css";
import {AiOutlineMenu} from "react-icons/ai";
import Utils from "../utils/Utils";
import pdfIcon from "../assets/pdf.png";

const FileManager = ({person, onSave, onClose}) => {
    const [employee, setEmployee] = useState(person);
    const [selectedFile, setSelectedFile] = useState(null);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        fetchFiles();
    }, []);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        uploadFile(event.target.files[0]);
    };

    const handleFileDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setSelectedFile(file);
        uploadFile(file);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const fetchFiles = () => {
        FileService.getFiles(employee.id)
            .then(response => {
                if (response.data !== null) {
                    setFiles(response);
                    console.log(files);
                }
            })
            .catch(error => {
                    console.log(error);
                }
            )
        ;
    };

    const downloadFile = async (file) => {
        let confirmation = window.confirm('Are you sure you want to download this file?');
        if (confirmation) {
            const base64Content = file.content;

            const byteCharacters = atob(base64Content);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = file.name;

            link.click();

            link.remove();

            URL.revokeObjectURL(url);
        }
    };

    const deleteFile = async (userId, file) => {
        let confirmation = window.confirm('Are you sure you want to delete this file?');
        if (confirmation) {
            try {
                await FileService.delete(userId, file);
                await fetchFiles();
            } catch (error) {
                // Handle any errors that occurred during deletion or fetching files
                console.log(error);
            }
        }
    };

    const renameFile = async (userId, file) => {
        // save file type
        const fileType = file.split('.').pop();
        // remove file type from file name
        const fileName = file.replace(/\.[^/.]+$/, "");
        let newNameInput = window.prompt('Enter new name for file', fileName);
        if (!newNameInput) {
            return;
        }
        let newName = newNameInput + '.' + fileType;
        if (newName) {
            try {
                await FileService.rename(userId, file, newName);
                await new Promise(resolve => setTimeout(resolve, 500));
                fetchFiles();
            } catch (error) {
                // Handle any errors that occurred during renaming or fetching files
                console.log(error);
            }
        }
    };

    const uploadFile = (file) => {
        // Check if file is an image or pdf
        if (!file.type.startsWith('image/') && file.type !== 'application/pdf') {
            alert('Please upload an image file or a PDF file');
            return;
        }
        FileService.uploadFile(employee.id, file)
            .then(() => {
                fetchFiles();
            });
    };

    return (
        <div className="file-form-overlay">
            <div className="file-form" onDrop={handleFileDrop} onDragOver={handleDragOver}>
                <h1>File Manager</h1>
                {files.map((file, index) => (
                    <div key={index} className="file-item">
                        {Utils.isImageFile(file) ? (
                            <img src={`data:image/png;base64,${file.file}`} alt={file.name} className="file-image" />
                        ) : (
                            <img src={pdfIcon} alt={file.name} className="file-image" />
                        )}
                        <p>{file.name}</p>
                        <div className="file-action-container">
                            <button className="file-action-button"><AiOutlineMenu /></button>
                            <div className="file-action-menu-content">
                                <button onClick={() => downloadFile(file)}>Download File</button>
                                <button onClick={() => renameFile(person.id, file.name)}>Rename File</button>
                                <button onClick={() => deleteFile(person.id, file.name)}>Delete File</button>
                            </div>
                        </div>
                    </div>
                ))}

                <input type="file" id="fileUpload" onChange={handleFileChange} style={{ display: 'none' }} />
                <label htmlFor="fileUpload" className="upload-btn">Upload</label>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );

};

export default FileManager;
