import axios from 'axios';
import {BASEURL} from "../Constants";

class UserService {

    getUsers() {
        return axios.get(`${BASEURL}/user`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.data;
            });
    }

    createUser(name, password, admin) {
        return axios.post(`${BASEURL}/user`, {
            name, password, admin
        },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                }
            }).then(response => {
                return response.data;
        });
    }

    getUser(id) {
        return axios.get(`${BASEURL}/user/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        })
            .then(response => {
                return response.data;
            });
    }

    updateUser(id, name, password, admin) {
        return axios.put(`${BASEURL}/user/${id}`, {
            id, name, password, admin
        },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                }
            }).then(response => {
            return response.data;
        })
    }

    deleteUser(id) {
        return axios.delete(`${BASEURL}/user/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            return response.data;
            });
        }
}
export default new UserService();
