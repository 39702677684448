// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 300px;
    position: absolute;
}

@media (max-width: 768px) {
    .upload-form-container {
        margin-left: 50px;
        position: static;
    }
}


.image {
    max-width: 100%;
    max-height: 400px;
    margin-top: 20px;
}

.upload-form-container button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #0098e5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.upload-form-container button:hover {
    background-color: #2194cc;
}

.loading {
    margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/UploadForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,sBAAsB;IACtB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI;QACI,iBAAiB;QACjB,gBAAgB;IACpB;AACJ;;;AAGA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".upload-form-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-wrap: wrap;\n    flex-direction: column;\n    margin-top: 20px;\n    margin-left: 300px;\n    position: absolute;\n}\n\n@media (max-width: 768px) {\n    .upload-form-container {\n        margin-left: 50px;\n        position: static;\n    }\n}\n\n\n.image {\n    max-width: 100%;\n    max-height: 400px;\n    margin-top: 20px;\n}\n\n.upload-form-container button {\n    margin-top: 10px;\n    padding: 10px 20px;\n    background-color: #0098e5;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.upload-form-container button:hover {\n    background-color: #2194cc;\n}\n\n.loading {\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
