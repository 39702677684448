import React, {useEffect, useState} from 'react';
import './EditForm.css';
import DocumentService from "../services/DocumentService";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import CountryUtils from "../utils/CountryUtils";
import Utils from "../utils/Utils";
import PizZipUtils from "pizzip/utils";
import {saveAs} from 'file-saver';

const StarterDocumentForm = ({person, onSave, onClose}) => {
    const [employee, setEmployee] = useState(person);
    const [document, setDocument] = useState(null);
    const [company, setCompany] = useState('');
    const [workedBefore, setWorkedBefore] = useState('niet waar');
    const [startersType, setStartersType] = useState('North-Star');

    useEffect(() => {
        DocumentService.getDocumentByEmployeeId(employee.id).then(response => {
            setDocument(response);
        });
    }, []);

    function loadFile(url, callback) {
        PizZipUtils.getBinaryContent(url, callback);
    }

    const generateDocument = () => {
        if (!document) {
            return; // Document not loaded yet
        }
        // replace spaces with -
        const type = startersType.replace(/\s/g, '-');
        const gender = Utils.getGenderFromCode(employee.gender);
        loadFile(
            type + '.docx',
            function (error, content) {
                if (error) {
                    throw error;
                }
                const zip = new PizZip(content);
                const doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });
                doc.setData({
                    first_name: employee.firstName,
                    name: employee.firstName + ' ' + employee.lastName,
                    address: employee.street,
                    zipcode_city: employee.zipcode + ' ' + employee.city,
                    country: 'Nederland',
                    last_name: employee.lastName,
                    birth_date: Utils.formatDate(employee.birthDate),
                    gender: gender,
                    phone: employee.telephoneNumber,
                    nationality: employee.nationality,
                    email: employee.email,
                    nationality_in_eu: CountryUtils.isEuNationalityString(employee.nationality),
                    bsn: employee.citizenServiceNumber,
                    document_type: Utils.documentTypeToName(document.documentType),
                    document_number: document.documentNumber,
                    document_issue_date: Utils.formatDate(document.dateOfIssue),
                    document_expiration_date: Utils.formatDate(document.expiryDate),
                    company: company,
                    worked_last_26_weeks: workedBefore,
                });
                try {
                    doc.render();
                } catch (error) {
                    // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
                    function replaceErrors(key, value) {
                        if (value instanceof Error) {
                            return Object.getOwnPropertyNames(value).reduce(function (
                                    error,
                                    key
                                ) {
                                    error[key] = value[key];
                                    return error;
                                },
                                {});
                        }
                        return value;
                    }
                    console.log(JSON.stringify({ error: error }, replaceErrors));

                    if (error.properties && error.properties.errors instanceof Array) {
                        const errorMessages = error.properties.errors
                            .map(function (error) {
                                return error.properties.explanation;
                            })
                            .join('\n');
                        console.log('errorMessages', errorMessages);
                        // errorMessages is a humanly readable message looking like this :
                        // 'The tag beginning with "foobar" is unopened'
                    }
                    throw error;
                }
                const out = doc.getZip().generate({
                    type: 'blob',
                    mimeType:
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                }); //Output the document using Data-URI
                saveAs(out, employee.firstName + ' ' + employee.lastName + ' ' + type + '.docx');
            }
        );
    };

    return (
        <div className="edit-form-overlay">
            <div className="edit-form">
                <h2 className="edit-form-title">Startersdocument</h2>
                <div className="edit-form-row">
                    <label htmlFor="startersType">Startersdocument for:</label>
                    <select
                        id="startersType"
                        value={startersType}
                        onChange={(e) => setStartersType(e.target.value)}
                        className="edit-form-input"
                    >
                        <option value='North-Star'> North Star</option>
                        <option value='Actief-Werkt'>Actief Werkt</option>
                        <option value='CSU'>CSU</option>
                    </select>
                </div>
                {/*    If startersType is not equal to CSU show the following forms*/}
                {startersType !== 'CSU' && startersType !== 'Actief-Werkt' &&
                <div className="edit-form-row">
                    <label htmlFor="company">Company Name:</label>
                    <input
                        id="company"
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        className="edit-form-input"
                    />
                </div>
                }
                {startersType !== 'CSU' &&
                <div className="edit-form-row">
                <label htmlFor="workedBefore">Worked for company before in the last 26 weeks:</label>
                <select
                    id="workedBefore"
                    value={workedBefore}
                    onChange={(e) => setWorkedBefore(e.target.value)}
                    className="edit-form-input"
                >
                    <option value='waar'>
                        True
                    </option>
                    <option value='niet waar'>
                        False
                    </option>
                </select>
            </div>
                }
                <button className="edit-form-save" onClick={generateDocument}>
                    Create Startersdocument
                </button>
                <button className="edit-form-close" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );

}; export default StarterDocumentForm;
