import axios from 'axios';
import {BASEURL} from "../Constants";

class AuthService {
    // Define a method for handling login requests

    async login(username, password) {
        try {
            const response = await axios.post(`${BASEURL}/login`, {username, password}, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                console.log(response.data);
                console.log(response.headers);
                const user = response.data;
                const token = response.headers.authorization.split(" ")[1];
                console.log(token);
                localStorage.setItem("token", token);
                localStorage.setItem("isAdmin", user.admin);
                return user;
            } else {
                throw new Error("Unauthorized");
            }
        } catch (error) {
            throw new Error(error.message);
        }
    }

    async logout() {
        axios.post(`${BASEURL}/logout`, {}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token"),
            }
        }).then(response => {
            localStorage.removeItem("token");
            localStorage.removeItem("isAdmin");
        });
    }
}
export default new AuthService();

