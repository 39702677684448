// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backup-container {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    max-width: 600px;
    padding: 20px;
    box-sizing: border-box;
}



h3 {
    margin-bottom: 10px;
}

.backup-btn {
    padding: 10px 20px;
    background-color: #0098e5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.backup-btn:hover {
    background-color: #2194cc;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Backup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;AAC1B;;;;AAIA;IACI,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".backup-container {\n    display: flex;\n    flex-direction: column;\n    margin: 20px auto;\n    max-width: 600px;\n    padding: 20px;\n    box-sizing: border-box;\n}\n\n\n\nh3 {\n    margin-bottom: 10px;\n}\n\n.backup-btn {\n    padding: 10px 20px;\n    background-color: #0098e5;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.backup-btn:hover {\n    background-color: #2194cc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
