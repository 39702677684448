class CountryUtils {

    /**
     * Get the nationality of a country from the country code
     * @param {string} countryCode
     * @returns {string} Nationality of the country
     */
    static getNationality(countryCode) {
        return this.LANDEN[countryCode];
    }

    /**
     * Checks if the nationality is within the European Union
     * @param nationality The nationality to check
     * @returns {boolean} If the Nationality is within the European Union
     */
    static isEuNationality(nationality) {
        return this.EU_NATIONALITEITEN.includes(nationality);
    }

    /**
     * Converts the response of isEuNationality to a string for the user to read
     * @param nationality The nationality to check
     * @returns {string} The string to display to the user
     */
    static isEuNationalityString(nationality) {
        if (this.isEuNationality(nationality)) {
            return 'ja';
        } else {
            return 'nee';
        }
    }

    static EU_NATIONALITIES = ["Austrian", "Belgian", "Bulgarian", "Croatian", "Cypriot", "Czech", "Danish", "Estonian",
        "Finnish", "French", "German", "Greek", "Hungarian", "Irish", "Italian", "Latvian", "Lithuanian",
        "Luxembourgish", "Maltese", "Dutch", "Polish", "Portuguese", "Romanian", "Slovak", "Slovenian", "Spanish",
        "Swedish"
    ];

    static EU_NATIONALITEITEN = ["Belgische", "Bulgaarse", "Kroatische", "Cypriotische", "Tsjechische", "Deense",
        "Estse", "Finse", "Franse", "Duitse", "Griekse", "Hongaarse", "Ierse", "Italiaanse", "Letse", "Litouwse",
        "Luxemburgse", "Maltese", "Nederlandse", "Oostenrijkse", "Poolse", "Portugese", "Roemeense", "Slowaakse",
        "Sloveense", "Spaanse", "Zweedse"]


    static LANDEN = {
        "AFG": "Afghaanse",
        "ALA": "Ålandseilandbewoonster",
        "ALB": "Albanese",
        "DZA": "Algerijnse",
        "ASM": "Amerikaans Samoaanse",
        "AND": "Andorrese",
        "AGO": "Angolese",
        "AIA": "Anguillaanse",
        "ATA": "Antarctische",
        "ATG": "Antiguaanse of Barbudaanse",
        "ARG": "Argentijnse",
        "ARM": "Armeense",
        "ABW": "Arubaanse",
        "AUS": "Australische",
        "AUT": "Oostenrijkse",
        "AZE": "Azerbeidzjaanse, Azeri",
        "BHS": "Bahamaanse",
        "BHR": "Bahreinse",
        "BGD": "Bangladesh",
        "BRB": "Barbadiaanse",
        "BLR": "Belarus",
        "BEL": "Belgische",
        "BLZ": "Belizaanse",
        "BEN": "Beninse, Beninois",
        "BMU": "Bermudaanse",
        "BTN": "Bhutaanse",
        "BOL": "Boliviaanse",
        "BES": "Bonairiaanse",
        "BIH": "Bosnisch of Herzegovina",
        "BWA": "Motswana, Botswaanse",
        "BVT": "Bouvet Island",
        "BRA": "Braziliaanse",
        "IOT": "Britse Indische Oceaanterritorium",
        "UMI": "Amerikaans",
        "VGB": "Britse Maagdeneilander",
        "VIR": "Maagdeneilander",
        "BRN": "Bruneise",
        "BGR": "Bulgaarse",
        "BFA": "Burkinese",
        "BDI": "Burundese",
        "CPV": "Kaapverdische",
        "KHM": "Cambodjaanse",
        "CMR": "Kameroense",
        "CAN": "Canadese",
        "CYM": "Caymanian",
        "CAF": "Centraal-Afrikaanse",
        "TCD": "Tsjadische",
        "CHL": "Chileense",
        "CHN": "Chinese",
        "CXR": "Christmas Island",
        "CCK": "Cocoseilandbewoonster",
        "COL": "Colombiaanse",
        "COM": "Comorese",
        "COG": "Congolese",
        "COD": "Congolese",
        "COK": "Cookeilandse",
        "CRI": "Costa Ricaanse",
        "CIV": "Ivoriaanse",
        "HRV": "Kroatische",
        "CUB": "Cubaanse",
        "CUW": "Curaçaose",
        "CYP": "Cypriotische",
        "CZE": "Tsjechische",
        "DNK": "Deense",
        "DJI": "Djiboutiaanse",
        "DMA": "Dominicaanse",
        "DOM": "Dominicaanse",
        "ECU": "Ecuadoraanse",
        "EGY": "Egyptische",
        "SLV": "Salvadoraanse",
        "GNQ": "Equatoriaal-Guineese",
        "ERI": "Eritrese",
        "EST": "Estische",
        "ETH": "Ethiopische",
        "FLK": "Falklandeilandse",
        "FRO": "Faeröerse",
        "FJI": "Fijische",
        "FIN": "Finse",
        "FRA": "Franse",
        "GUF": "Frans-Guyaansese",
        "PYF": "Frans-Polynesische",
        "ATF": "Franse Zuidelijke en Antarctische Gebiedenese",
        "GAB": "Gaboneese",
        "GMB": "Gambiaanse",
        "GEO": "Georgische",
        "DEU": "Duitse",
        "GHA": "Ghanese",
        "GIB": "Gibraltarese",
        "GRC": "Griekse",
        "GRL": "Groenlandse",
        "GRD": "Grenadiaanse",
        "GLP": "Guadeloupse",
        "GUM": "Guamese",
        "GTM": "Guatemalteekse",
        "GGY": "Guernseyse",
        "GIN": "Guineeërse",
        "GNB": "Guinee-Bissauiaanse",
        "GUY": "Guyaanse",
        "HTI": "Haïtiaanse",
        "HMD": "Heard en McDonaldeilandsese",
        "VAT": "Heilige Stoel (Vaticaanstad)se",
        "HND": "Hondurese",
        "HKG": "Hongkongse",
        "HUN": "Hongaarse",
        "ISL": "IJslandse",
        "IND": "Indiase",
        "IDN": "Indonesische",
        "IRN": "Iraanse",
        "IRQ": "Iraakse",
        "IRL": "Ierse",
        "IMN": "Manxe",
        "ISR": "Israëlische",
        "ITA": "Italiaanse",
        "JAM": "Jamaicaanse",
        "JPN": "Japanse",
        "JEY": "Jerseyse",
        "JOR": "Jordaanse",
        "KAZ": "Kazachse, Kazachse",
        "KEN": "Keniaanse",
        "KIR": "Kiribatischse",
        "KWT": "Koeweitse",
        "KGZ": "Kirgizische, Kirgizische",
        "LAO": "Laotiaanse",
        "LVA": "Letse",
        "LBN": "Libanese",
        "LSO": "Lesothaanse",
        "LBR": "Liberiaanse",
        "LBY": "Libische",
        "LIE": "Liechtensteinse",
        "LTU": "Litouwse",
        "LUX": "Luxemburgse",
        "MAC": "Macaose",
        "MDG": "Malagassische",
        "MWI": "Malawische",
        "MYS": "Maleisische",
        "MDV": "Maldivische",
        "MLI": "Malinese",
        "MLT": "Maltese",
        "MHL": "Marshalleilands",
        "MTQ": "Martiniquaise",
        "MRT": "Mauritaanse",
        "MUS": "Mauritiaanse",
        "MYT": "Mayottees",
        "MEX": "Mexicaanse",
        "FSM": "Micronesische",
        "MDA": "Moldavische",
        "MCO": "Monegaskische",
        "MNG": "Mongoolse",
        "MNE": "Montenegrijnse",
        "MSR": "Montserratiaanse",
        "MAR": "Marokkaanse",
        "MOZ": "Mozambikaanse",
        "MMR": "Myanmarese (Birmaanse)",
        "NAM": "Namibische",
        "NRU": "Nauruaanse",
        "NPL": "Nepalese",
        "NLD": "Nederlandse",
        "NCL": "Nieuw-Caledonische",
        "NZL": "Nieuw-Zeelandse",
        "NIC": "Nicaraguaanse",
        "NER": "Nigerees",
        "NGA": "Nigeriaanse",
        "NIU": "Niuese",
        "NFK": "Norfolkeilandse",
        "PRK": "Noord-Koreaanse",
        "MNP": "Noordelijke Marianese",
        "NOR": "Noorse",
        "OMN": "Omaanse",
        "PAK": "Pakistaanse",
        "PLW": "Palauese",
        "PSE": "Palestijnse",
        "PAN": "Panamese",
        "PNG": "Papoea-Nieuw-Guinese",
        "PRY": "Paraguayaanse",
        "PER": "Peruaanse",
        "PHL": "Filipijnse",
        "PCN": "Pitcairneilandse",
        "POL": "Poolse",
        "PRT": "Portugese",
        "PRI": "Puerto Ricaanse",
        "QAT": "Qatarese",
        "ROU": "Roemeense",
        "RUS": "Russische",
        "RWA": "Rwandese",
        "REU": "Réunionese",
        "BLM": "Saint-Barthélemy-eilandse",
        "SHN": "Sint-Helenaanse",
        "KNA": "Kittiaanse of Nevisiaanse",
        "LCA": "Saint Luciaanse",
        "MAF": "Saint-Martiniaanse",
        "SPM": "Saint-Pierraise of Miquelonnais",
        "VCT": "Saint Vincentiaanse",
        "WSM": "Samoaanse",
        "SMR": "San Marineese",
        "STP": "Sao Tomé en Principeaanse",
        "SAU": "Saoedische",
        "SEN": "Senegalese",
        "SRB": "Servische",
        "SYC": "Seychelliaanse",
        "SLE": "Sierra Leoonse",
        "SGP": "Singaporese",
        "SXM": "Sint Maartenseilandse",
        "SVK": "Slowaakse",
        "SVN": "Sloveense",
        "SLB": "Salomonseilandse",
        "SOM": "Somalische",
        "ZAF": "Zuid-Afrikaanse",
        "SGS": "Zuid-Georgiaanse of Zuid-Sandwich-eilandse",
        "SSD": "Zuid-Soedanese",
        "ESP": "Spaanse",
        "LKA": "Sri Lankaanse",
        "SDN": "Soedanese",
        "SUR": "Surinaamse",
        "SJM": "Svalbardse",
        "SWZ": "Swazische",
        "SWE": "Zweedse",
        "CHE": "Zwitserse",
        "SYR": "Syrische",
        "TWN": "Taiwanese",
        "TJK": "Tadzjiekse",
        "TZA": "Tanzaniaanse",
        "THA": "Thaise",
        "TLS": "Oost-Timorese",
        "TGO": "Togolese",
        "TKL": "Tokelauese",
        "TON": "Tongaanse",
        "TTO": "Trinidadiaanse of Tobagoniaanse",
        "TUN": "Tunesische",
        "TUR": "Turkse",
        "TKM": "Turkmeense",
        "TCA": "Turks- en Caicoseilands",
        "TUV": "Tuvaluaanse",
        "UGA": "Oegandese",
        "UKR": "Oekraïense",
        "ARE": "Emiratische",
        "GBR": "Britse",
        "USA": "Amerikaanse",
        "URY": "Uruguayaanse",
        "UZB": "Oezbeekse",
        "VUT": "Ni-Vanuatuaanse",
        "VEN": "Venezolaanse",
        "VNM": "Vietnamese",
        "WLF": "Wallis en Futunaanse",
        "ESH": "Westelijke Saharase",
        "YEM": "Jemenitische",
        "ZMB": "Zambiaanse",
        "ZWE": "Zimbabwaanse"
    }

    static COUNTRIES = {
        "AFG": "Afghan",
        "ALA": "Åland Islander",
        "ALB": "Albanian",
        "DZA": "Algerian",
        "ASM": "American Samoan",
        "AND": "Andorran",
        "AGO": "Angolan",
        "AIA": "Anguillan",
        "ATA": "Antarctic",
        "ATG": "Antiguan or Barbudan",
        "ARG": "Argentine",
        "ARM": "Armenian",
        "ABW": "Aruban",
        "AUS": "Australian",
        "AUT": "Austrian",
        "AZE": "Azerbaijani, Azeri",
        "BHS": "Bahamian",
        "BHR": "Bahraini",
        "BGD": "Bangladeshi",
        "BRB": "Barbadian",
        "BLR": "Belarusian",
        "BEL": "Belgian",
        "BLZ": "Belizean",
        "BEN": "Beninese, Beninois",
        "BMU": "Bermudian, Bermudan",
        "BTN": "Bhutanese",
        "BOL": "Bolivian",
        "BES": "Bonaire",
        "BIH": "Bosnian or Herzegovinian",
        "BWA": "Motswana, Botswanan",
        "BVT": "Bouvet Island",
        "BRA": "Brazilian",
        "IOT": "British Indian Ocean Territory",
        "UMI": "American",
        "VGB": "British Virgin Islander",
        "VIR": "Virgin Islander",
        "BRN": "Bruneian",
        "BGR": "Bulgarian",
        "BFA": "Burkinabé",
        "BDI": "Burundian",
        "CPV": "Cabo Verdean",
        "KHM": "Cambodian",
        "CMR": "Cameroonian",
        "CAN": "Canadian",
        "CYM": "Caymanian",
        "CAF": "Central African",
        "TCD": "Chadian",
        "CHL": "Chilean",
        "CHN": "Chinese",
        "CXR": "Christmas Island",
        "CCK": "Cocos Islander",
        "COL": "Colombian",
        "COM": "Comoran, Comorian",
        "COG": "Congolese",
        "COD": "Congolese",
        "COK": "Cook Islander",
        "CRI": "Costa Rican",
        "CIV": "Ivorian",
        "HRV": "Croatian",
        "CUB": "Cuban",
        "CUW": "Curaçaoan",
        "CYP": "Cypriot",
        "CZE": "Czech",
        "DNK": "Danish",
        "DJI": "Djiboutian",
        "DMA": "Dominican",
        "DOM": "Dominican",
        "ECU": "Ecuadorian",
        "EGY": "Egyptian",
        "SLV": "Salvadoran",
        "GNQ": "Equatorial Guinean, Equatoguinean",
        "ERI": "Eritrean",
        "EST": "Estonian",
        "ETH": "Ethiopian",
        "FLK": "Falkland Islander",
        "FRO": "Faroese",
        "FJI": "Fijian",
        "FIN": "Finnish",
        "FRA": "French",
        "GUF": "French Guianese",
        "PYF": "French Polynesian",
        "ATF": "French Southern Territories",
        "GAB": "Gabonese",
        "GMB": "Gambian",
        "GEO": "Georgian",
        "DEU": "German",
        "GHA": "Ghanaian",
        "GIB": "Gibraltar",
        "GRC": "Greek",
        "GRL": "Greenlander",
        "GRD": "Grenadian",
        "GLP": "Guadeloupean",
        "GUM": "Guamanian",
        "GTM": "Guatemalan",
        "GGY": "Channel Islander (Guernsey)",
        "GIN": "Guinean",
        "GNB": "Guinea-Bissauan",
        "GUY": "Guyanese",
        "HTI": "Haitian",
        "HMD": "Heard Island and McDonald Islands",
        "VAT": "Vatican",
        "HND": "Honduran",
        "HKG": "Hong Kong",
        "HUN": "Hungarian",
        "ISL": "Icelandic",
        "IND": "Indian",
        "IDN": "Indonesian",
        "IRN": "Iranian",
        "IRQ": "Iraqi",
        "IRL": "Irish",
        "IMN": "Manx",
        "ISR": "Israeli",
        "ITA": "Italian",
        "JAM": "Jamaican",
        "JPN": "Japanese",
        "JEY": "Channel Islander (Jersey)",
        "JOR": "Jordanian",
        "KAZ": "Kazakhstani",
        "KEN": "Kenyan",
        "KIR": "Kiribati",
        "KWT": "Kuwaiti",
        "KGZ": "Kyrgyzstani",
        "LAO": "Laotian",
        "LVA": "Latvian",
        "LBN": "Lebanese",
        "LSO": "Basotho",
        "LBR": "Liberian",
        "LBY": "Libyan",
        "LIE": "Liechtenstein",
        "LTU": "Lithuanian",
        "LUX": "Luxembourgish",
        "MAC": "Macanese",
        "MDG": "Malagasy",
        "MWI": "Malawian",
        "MYS": "Malaysian",
        "MDV": "Maldivian",
        "MLI": "Malian",
        "MLT": "Maltese",
        "MHL": "Marshallese",
        "MTQ": "Martinican",
        "MRT": "Mauritanian",
        "MUS": "Mauritian",
        "MYT": "Mahoran",
        "MEX": "Mexican",
        "FSM": "Micronesian",
        "MDA": "Moldovan",
        "MCO": "Monégasque",
        "MNG": "Mongolian",
        "MNE": "Montenegrin",
        "MSR": "Montserratian",
        "MAR": "Moroccan",
        "MOZ": "Mozambican",
        "MMR": "Burmese",
        "NAM": "Namibian",
        "NRU": "Nauruan",
        "NPL": "Nepali",
        "NLD": "Dutch",
        "NCL": "New Caledonian",
        "NZL": "New Zealander",
        "NIC": "Nicaraguan",
        "NER": "Nigerien",
        "NGA": "Nigerian",
        "NIU": "Niuean",
        "NFK": "Norfolk Islander",
        "MNP": "Northern Mariana Islander",
        "NOR": "Norwegian",
        "OMN": "Omani",
        "PAK": "Pakistani",
        "PLW": "Palauan",
        "PSE": "Palestinian",
        "PAN": "Panamanian",
        "PNG": "Papua New Guinean",
        "PRY": "Paraguayan",
        "PER": "Peruvian",
        "PHL": "Filipino",
        "PCN": "Pitcairn Islander",
        "POL": "Polish",
        "PRT": "Portuguese",
        "PRI": "Puerto Rican",
        "QAT": "Qatari",
        "ROU": "Romanian",
        "RUS": "Russian",
        "RWA": "Rwandan",
        "REU": "Réunionese",
        "BLM": "Saint Barthélemy Islander",
        "SHN": "Saint Helenian",
        "KNA": "Kittitian or Nevisian",
        "LCA": "Saint Lucian",
        "MAF": "Saint-Martinoise",
        "SPM": "Saint-Pierrais or Miquelonnais",
        "VCT": "Saint Vincentian",
        "WSM": "Samoan",
        "SMR": "Sammarinese",
        "STP": "São Toméan",
        "SAU": "Saudi Arabian",
        "SEN": "Senegalese",
        "SRB": "Serbian",
        "SYC": "Seychellois",
        "SLE": "Sierra Leonean",
        "SGP": "Singaporean",
        "SXM": "Sint Maarten Islander",
        "SVK": "Slovak",
        "SVN": "Slovenian",
        "SLB": "Solomon Islander",
        "SOM": "Somali",
        "ZAF": "South African",
        "SGS": "South Georgia or South Sandwich Islands",
        "SSD": "South Sudanese",
        "ESP": "Spanish",
        "LKA": "Sri Lankan",
        "SDN": "Sudanese",
        "SUR": "Surinamese",
        "SJM": "Svalbard",
        "SWZ": "Swazi",
        "SWE": "Swedish",
        "CHE": "Swiss",
        "SYR": "Syrian",
        "TWN": "Taiwanese",
        "TJK": "Tajikistani",
        "TZA": "Tanzanian",
        "THA": "Thai",
        "TLS": "East Timorese",
        "TGO": "Togolese",
        "TKL": "Tokelauan",
        "TON": "Tongan",
        "TTO": "Trinidadian or Tobagonian",
        "TUN": "Tunisian",
        "TUR": "Turkish",
        "TKM": "Turkmen",
        "TCA": "Turks and Caicos Islander",
        "TUV": "Tuvaluan",
        "UGA": "Ugandan",
        "UKR": "Ukrainian",
        "ARE": "Emirati",
        "GBR": "British",
        "USA": "American",
        "URY": "Uruguayan",
        "UZB": "Uzbek",
        "VUT": "Ni-Vanuatu",
        "VEN": "Venezuelan",
        "VNM": "Vietnamese",
        "WLF": "Wallis and Futuna Islander",
        "ESH": "Sahrawi",
        "YEM": "Yemeni",
        "ZMB": "Zambian",
        "ZWE": "Zimbabwean"
    }
}

export default CountryUtils;
